import React, { useEffect } from 'react';
import { Amplify } from 'aws-amplify';

import * as AuthUtils from '../utils/AuthUtils.js';
import * as ReactUtils from "../utils/ReactUtils.js";
import * as Utils from '../utils/Utils.js';
import "semantic-ui-css/semantic.min.css"
import TranslateGPTImage from '../images/translateGPT_128.png';

function $translateLanguageSelectionElement() { return document.getElementById("languageSelectionId"); }
function $voiceSpeedSelectionElement() { return document.getElementById("voiceSpeedId"); }

function $needSignUpOrLoginSectionElement() { return document.getElementById('needSignUpOrLoginSectionId'); }
function $accountDetailsSectionElement() { return document.getElementById('accountDetailsSectionId'); }
function $emailAddressElement() { return document.getElementById('emailAddressId'); }
function $signOutButtonElement() { return document.getElementById('signOutButtonId'); }
function $startSignUpOrLoginButtonElement() { return document.getElementById('startSignUpOrLoginButtonId'); }

function $upgradeToPremiumServiceButtonElement() { return document.getElementById('upgradeToPremiumSerivceButtonId'); }
function $manageYourSubscriptionButtonElement() { return document.getElementById('manageSubscriptionButtonId'); }
function $subcriptionSectionElement() { return document.getElementById('subscriptionSectionId'); }

const saveOptions = () => {
  const languageTranslateTo = $translateLanguageSelectionElement().value;

  Utils.setLocalStorageProperty(Utils.SETTINGS_LANGUAGE_TRANSLATE_TO, languageTranslateTo);
  Utils.setLocalStorageProperty(Utils.SETTINGS_TTS_VOICE_SPEED, $voiceSpeedSelectionElement().value);

  const status = document.getElementById('status');
  status.textContent = 'Settings saved.';
  setTimeout(() => {status.textContent = '';}, 750);
};

// Restores select box and checkbox state using the preferences
const initOptionsUI = async () => {
  var languagesTranslateTo = Utils.getLocalStorageProperty(Utils.SETTINGS_LANGUAGE_TRANSLATE_TO) ?? Utils.DEFAULT_DESTINATION_LANGUAGE;
  Utils.initTranslateLanguageElement(languagesTranslateTo, $translateLanguageSelectionElement());

  var voiceSpeed = Utils.getLocalStorageProperty(Utils.SETTINGS_TTS_VOICE_SPEED) ?? Utils.DEFAULT_TTS_VOICE_SPEED;
  // console.log('voiceSpeed', Utils.getLocalStorageProperty(Utils.SETTINGS_TTS_VOICE_SPEED), voiceSpeed);
  Object.keys(Utils.TTS_VOICE_SPEED).forEach(speedKey => {
    Utils.addSelectOptionToElement($voiceSpeedSelectionElement(), Utils.TTS_VOICE_SPEED[speedKey], speedKey, voiceSpeed);
  });

  await loadAccountDetailsAndSubscriptionSection();
};

async function loadAccountDetailsAndSubscriptionSection() {
  //console.log("loadAccountDetailsAndSubscriptionSection");
  const user = await AuthUtils.currentAuthenticatedUser();
  var subscriptionState = Utils.SubscriptionState.NONE;

  Utils.hideHTMLElement($manageYourSubscriptionButtonElement());
  Utils.hideHTMLElement($upgradeToPremiumServiceButtonElement());

  if (user) {
    Utils.hideHTMLElement($needSignUpOrLoginSectionElement());
    Utils.showHTMLElement($accountDetailsSectionElement());
    Utils.showHTMLElement($subcriptionSectionElement());

    const userInfo = await AuthUtils.currentUserInfo();
    $emailAddressElement().textContent = userInfo?.attributes?.email;
    $signOutButtonElement().addEventListener('click', 
      () => { ReactUtils.showUserSignInOrOutPopup(user, $accountDetailsSectionElement(), false, loadAccountDetailsAndSubscriptionSection); });

    subscriptionState = await Utils.getSubscriptionState();
    //console.log("subscriptionState is ", subscriptionState, subscriptionState == Utils.SubscriptionState.NONE);
    if (subscriptionState === Utils.SubscriptionState.NONE) {
      Utils.showHTMLElement($upgradeToPremiumServiceButtonElement());
      $upgradeToPremiumServiceButtonElement().addEventListener('click', 
        () => ReactUtils.showPurchasePremiumPopup($upgradeToPremiumServiceButtonElement(), userInfo?.attributes?.email));
    } else {
      Utils.showHTMLElement($manageYourSubscriptionButtonElement());
      $manageYourSubscriptionButtonElement().addEventListener('click', onOpenManageSubscriptionLink);
    }
  } else {
    Utils.hideHTMLElement($accountDetailsSectionElement());
    Utils.showHTMLElement($needSignUpOrLoginSectionElement());
    Utils.hideHTMLElement($subcriptionSectionElement());
    
    $startSignUpOrLoginButtonElement().addEventListener('click', 
      () => { ReactUtils.showUserSignInOrOutPopup(user, $needSignUpOrLoginSectionElement(), false, loadAccountDetailsAndSubscriptionSection); });
  }
}

const onOpenManageSubscriptionLink = () => {
  window.location.href = 'https://billing.stripe.com/p/login/6oE4hl3LN1bk0W4000';
}

const SettingsPage = () => {
  useEffect(() => {
    console.log("load useeffect");
    initOptionsUI();
    document.getElementById('saveSettingsButtonId').addEventListener('click', saveOptions);

  }, []);

  return (
    <div>
      <meta charSet="utf-8" />
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/water.css@2/out/water.css"></link>
      <title>TranslateGPT</title>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={TranslateGPTImage} alt="logo" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
        <p style={{ fontSize: '17px' }}>TranslateGPT</p>
      </div>

      <div className="ui divider"></div>
      <h3 style={{ margin: '30px 0 20px 0' }}>Settings</h3>
      <label style={{ paddingRight: '10px' }}><b>Translate to</b></label>
      <select id="languageSelectionId" style={{ display: 'inline' }}>
      </select>
      <div style={{margin: '10px 0px'}}>
        <label style={{ paddingRight: '10px' }}><b>Voice Speed</b></label>
        <select id="voiceSpeedId" style={{ display: 'inline' }}>
        </select>
      </div>
      <br />

      <button id="saveSettingsButtonId">Save</button>
      <div id="status"></div>

      <div className="ui divider"></div>
      <h3 style={{ margin: '10px 0' }}>Account</h3>
      <div id="needSignUpOrLoginSectionId">
        <div style={{ fontSize: '15px', margin: '0 0 15px' }}>You haven't signed in yet, sign in and start translating.</div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button id="startSignUpOrLoginButtonId" style={{ margin: '15px 0px', width: '60%' }} className="ui primary button"><b>Sign up or Log in</b></button>
        </div>
      </div>
      <div id="accountDetailsSectionId">
        <label style={{ paddingRight: '10px' }}><b>Email:</b></label>
        <label id="emailAddressId" style={{ paddingRight: '10px' }}></label>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button id="signOutButtonId" style={{ margin: '15px 0px', width: '60%' }} className="ui basic button">Sign out</button>
        </div>
      </div>

      <div id="subscriptionSectionId">
        <div className="ui divider"></div>
        <h3 style={{ margin: '10px 0' }}>Subscription</h3>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button id="upgradeToPremiumSerivceButtonId" style={{ margin: '15px 0px', width: '60%' }} className="ui primary button"><b>Upgrade to Premium Service</b></button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button id="manageSubscriptionButtonId" style={{ margin: '15px 0px', width: '60%' }} className="ui basic button">Manage your subscription</button>
        </div>
      </div>

    </div>
  );
}

export default SettingsPage;