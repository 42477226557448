import React, { useRef, useState, useEffect } from 'react';
import "@aws-amplify/ui-react/styles.css";
import "semantic-ui-css/semantic.min.css"
import { Link, useNavigate } from 'react-router-dom';

import {AUTO_DETECT_LANGUAGE_VALUE, LANGUAGE_MAP, SOURCE_LANGUAGE_MAP} from "./utils/LanguageMap.js";
import * as AuthUtils from './utils/AuthUtils.js';
import * as ReactUtils from "./utils/ReactUtils.js";
import * as Utils from './utils/Utils.js';
import Footer from './ui_views/Footer';
import FileUpload from './ui_views/FileUpload';
import AdComponent from './ui_views/AdsComponent';
import TextToSpeechComponent from './ui_views/TextToSpeechComponent';
import * as StringUtils from './utils/Strings.js';

import appLogo from "./images/translateGPT_128.png";
import copyImage from "./images/copy.png";
import rocketImage from "./images/rocket.gif";
import clearIcon from "./images/close.png";
import settingsImage from "./images/settings.png";
import switchArrowIcon from "./images/switch_arrow.png";
import historyImage from "./images/history.png";

const IS_USE_TEST_URL = false;
const TRANSLATE_TEST_URL = "https://vpidarfpkzwv25hrjglal6bv4a0szqry.lambda-url.us-west-2.on.aws/";
const TRANSLATE_URL = IS_USE_TEST_URL ? TRANSLATE_TEST_URL : "https://bjostwnl2hsnuhllkomqivfrta0wabjp.lambda-url.us-west-2.on.aws";

const TRANSLATE_TEXT_MENU_ID = "translateTextMenuId";
const TRANSLATE_DOCUMENT_MENU_ID = "translateDocumentMenuId";
const TRANSLATE_IMAGE_MENU_ID = "translateImageMenuId";

const TRANSLATE_FAILED_ERROR_MESSAGE = "Translate failed, please try again. ";
const TRANSALTE_FAILED_WITH_NULL_RESPONSE_ERROR_MESSAGE = "Translate failed: response is null";

const USER_ACTION = {
  TRANSLITERATE: "Transliterate", 
  DICTIONARY: "Dictionary",
  SYNONYMS: "Synonyms",
  PRONUNCIATIONS: 'Pronunciations'
}

export const HomePage = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(TRANSLATE_TEXT_MENU_ID);
  const handleMainMenuItemClick = (event) => {
    if (isUserSignedIn) {
      setActiveMenuItem(event.target.id);
    } else {
      const $translateSection = document.getElementById("translateSectionId");
      ReactUtils.showUserSignInOrOutPopup(null, $translateSection, false, updateUserSignedInStateAndEmail)
    }
  };
  const navigate = useNavigate();

  const [textToTranslateValue, setTextToTranslateValue] = useState('');
  const [translatedTextValue, setTranslatedTextValue] = useState('');
  const [sourceLanguageValue, setSourceLanguage] = useState(
    Utils.getLocalStorageProperty(Utils.SETTINGS_LANGUAGE_SOURCE_TRANSLATE_FROM) ?? Utils.DEFAULT_AUTO_DETECT_SOURCE_LANGUAGE);
  const [destinationLanguageValue, setDestinationLanguage] = useState(
    Utils.getLocalStorageProperty(Utils.SETTINGS_LANGUAGE_TRANSLATE_TO) ?? Utils.DEFAULT_DESTINATION_LANGUAGE);
  const [translatorValue, setTranslatorValue] = useState(
    Utils.getLocalStorageProperty(Utils.SETTINGS_TRANSLATOR_SELECTED) ?? Utils.DEFAULT_TRANSLATOR);
  const [translateTone, setTranslateTone] = useState(
      Utils.getLocalStorageProperty(Utils.SETTINGS_TRANSLATE_TONE) ?? "");
  const handleTextToTranslateChange = (event) => {
    setTextToTranslateValue(event.target.value);
  };
  const handleTranslatedTextChange = (event) => {
    setTranslatedTextValue(event.target.value);
  } 
  const handleSourceLanguageChange = (event) => {
    setSourceLanguage(event.target.value);
  };
  const handleDestinationLanguageChange = (event) => {
    setDestinationLanguage(event.target.value);
  };
  const handleTranslatorChange = (event) => {
    setTranslatorValue(event.target.value);
  };
  const [otherOutputValue, setOtherOutputValue] = useState('');

  const handleTranslateToneChange = (event) => {
    const tone = event.target.value;
    setTranslateTone(tone);
    Utils.setLocalStorageProperty(Utils.SETTINGS_TRANSLATE_TONE, tone);
  };

  const [isMenuSidebarOpen, setIsMenuSidebarOpen] = useState(false);
  const onMenuSidebarClick = () => {
    //console.log("onMenuSidebarClick get clicked");
    setIsMenuSidebarOpen(!isMenuSidebarOpen);
  }
  const sidebarMenuRef = useRef(null);
  const handleOutsideOfSidebarMenuClick = (event) => {
    //console.log("handleOutsideOfSidebarMenuClick get clicked", event.target.id);
    if (sidebarMenuRef.current && !sidebarMenuRef.current.contains(event.target) && event.target.id !== "sideBarMenuId") {

      setIsMenuSidebarOpen(false);
    }
  };

  const premiumSectionRef = useRef(null);
  const onScrollToPremiumSection = () => {
    premiumSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const productsSectionRef = useRef(null);
  const onScrollToProductsSection = () => {
    productsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const onCopyTranslatedText = () => {
    navigator.clipboard.writeText(translatedTextValue);
  }
  const onSwitchLanguage = () => {
    const previousSourceLanguage = sourceLanguageValue;
    setSourceLanguage(destinationLanguageValue);
    setDestinationLanguage(previousSourceLanguage);

    const previousTextToTranslate = textToTranslateValue;
    setTextToTranslateValue(translatedTextValue);
    setTranslatedTextValue(previousTextToTranslate);
  }
  const onShowHistoryButtonClick = () => {
    if (isUserSubscribed) {
      navigate('/history');
    } else {
      ReactUtils.showPurchasePremiumPopup(document.getElementById('textTotranslateFieldId'), userEmail);
    }
  }

  const [translatedTextareaColor, setTranslatedTextareaColor] = useState('black');

  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);
  const [subscribedState, setSubscribedState] = useState(Utils.SubscriptionState.NONE);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');

  useEffect(() => { 
    updateUserSignedInStateAndEmail(); 

    document.addEventListener('click', handleOutsideOfSidebarMenuClick);
    return () => {
      document.removeEventListener('click', handleOutsideOfSidebarMenuClick);
    };
  }, []);
  const updateUserSignedInStateAndEmail = async () => {
    const accessToken = await Utils.getUserAccesstoken();
    if (accessToken) {
      setIsUserSignedIn(true);

      const userInfo = await AuthUtils.currentUserInfo();
      if (userInfo?.attributes?.email) {
        setUserEmail(userInfo?.attributes?.email);
      }

      if (userInfo?.attributes?.name) {
        setUserName(userInfo?.attributes?.name);
      }

      const subscriptionState = await Utils.getSubscriptionState(); // Utils.SubscriptionState.PREMIUM
      setSubscribedState(subscriptionState);
      setIsUserSubscribed(Utils.isUserSubscribed(subscriptionState));
      // setIsUserSubscribed(true);
    }
  };

  const [isShowLoadingDimmer, setIsShowLoadingDimmer] = useState(false);

  const onClickSignup = async () => {
    const $translateSection = document.getElementById("translateSectionId");
    ReactUtils.showUserSignInOrOutPopup(null, $translateSection, true, updateUserSignedInStateAndEmail);
  }

  const onClickLogin = async () => {
    const $translateSection = document.getElementById("translateSectionId");
    ReactUtils.showUserSignInOrOutPopup(null, $translateSection, false, updateUserSignedInStateAndEmail);
  }

  const navigateToChromeExtension = () => {
    window.open('https://chrome.google.com/webstore/detail/translategpt-translate-al/dnffnnhonjgemhiegeenagikblhnfhaa');
  };

  async function translate(inputRequest, translateType, inputTextToTranslate) {
    const user = await AuthUtils.currentAuthenticatedUser();
    if (user) {
      const session = await AuthUtils.currentSession();
      if (session?.getAccessToken()?.getJwtToken()) {
        try {
          const isExceedRequestLimitEveryMin = await Utils.getIsExcceedRequestLimitEveryMin();
          if (isExceedRequestLimitEveryMin) {
            setTranslatedTextValue(`You have exceeded the request rate limit per minute with free version. Please retry after a couple of mins or Upgrade to Premium Service package below with unlimited usage, advanced Premium features and 8x longer translation support!`);
            setTranslatedTextareaColor('red');
            return;
          } else if (Utils.getIfReachDailyRequestLimit(subscribedState)) {
            setTranslatedTextValue(StringUtils.ExcceedDailyRequestForFreeTrialErrorMessage);
            setTranslatedTextareaColor('red');
            return;
        } else if (Utils.getIfReachDailyTranslatedCharsLimit(subscribedState)) {
            setTranslatedTextValue(StringUtils.ExcceedDailyTranslatedCharsForFreeTrialErrorMessage)
            setTranslatedTextareaColor('red');
            return;
        }  

          let requestData = {
            destinationLanguage: destinationLanguageValue,
            translator: translatorValue,
            translateTone: translateTone,
            accessToken: session?.getAccessToken()?.getJwtToken(),
            sourceLanguage: (sourceLanguageValue !== AUTO_DETECT_LANGUAGE_VALUE) ? sourceLanguageValue : ''
          };

          requestData = { ...inputRequest, ...requestData }

          console.log("translate request data", inputRequest, requestData);
          
          setIsShowLoadingDimmer(true);
          const response = await Utils.awsClient.fetch(`${TRANSLATE_URL}/my-lambda/invocations`, 
            { body: JSON.stringify(requestData) });

          console.log("translate response is", response);
          setIsShowLoadingDimmer(false);

          const translatedResponse = await response.json();
          console.log("translate Json response is", translatedResponse);

          if (translatedResponse) {
            Utils.setSubscriptionState(translatedResponse.subscriptionState);
            Utils.setLocalStorageProperty(Utils.SETTINGS_LANGUAGE_TRANSLATE_TO, destinationLanguageValue);
            Utils.setLocalStorageProperty(Utils.SETTINGS_TRANSLATOR_SELECTED, translatorValue);

            setTranslatedTextareaColor('black');
            if (translateType === Utils.TRANSLATE_TYPE.TEXT) {
              setTranslatedTextValue(translatedResponse.translatedText);
              if (translatedResponse.translatedText) {
                Utils.setLastTranslateTimeStamp();
                Utils.increaseDailyRequest();
                Utils.increaseDailyTranslatedCharsMade(inputTextToTranslate?.length);
                Utils.addToTranslationHistory(inputTextToTranslate, translatedResponse.translatedText);
              }
            } else if (translateType === Utils.TRANSLATE_TYPE.IMAGE) {
              if (translatedResponse.translatedText) {
                Utils.addToTranslationHistory(inputTextToTranslate, translatedResponse.translatedText);
              }
              return { [Utils.OBJECT_KEY_FOR_TRANSLATED_IMAGE_TEXT]: translatedResponse.translatedText};
            } else if (translateType === Utils.TRANSLATE_TYPE.DOCUMENT) {
              if (translatedResponse?.key) {
                return { [Utils.OBJECT_KEY_FOR_FILE_SERVER_KEY]: translatedResponse.key, [Utils.OBJECT_KEY_FOR_FILE_URL]: translatedResponse.fileUrl};
              } else {
                console.error("Translate document fails ", translatedResponse);
                return {errorMessage: TRANSLATE_FAILED_ERROR_MESSAGE + translatedResponse.error};
              }
            }

            if (translatedResponse.sourceLanguage && sourceLanguageValue === AUTO_DETECT_LANGUAGE_VALUE) {
              setSourceLanguage(translatedResponse.sourceLanguage);
            }
          } else {
            if (translateType === Utils.TRANSLATE_TYPE.TEXT) {
              setTranslatedTextValue(TRANSALTE_FAILED_WITH_NULL_RESPONSE_ERROR_MESSAGE);
              setTranslatedTextareaColor('red');
            } else {
              return {errorMessage: TRANSALTE_FAILED_WITH_NULL_RESPONSE_ERROR_MESSAGE};
            }
          }
        } catch(error) {
          console.error("translate text failed", error.message);
          setIsShowLoadingDimmer(false);
          if (translateType === Utils.TRANSLATE_TYPE.TEXT) {
            setTranslatedTextValue("Translate failed: " + error.message);
            setTranslatedTextareaColor('red');
          } else {
            return {errorMessage: error.message};
          }
        }
      } else {
        console.error("onClickTranslateButton(): get session failed for user", user);
        const $translateSection = document.getElementById("translateSectionId");
        ReactUtils.showUserSignInOrOutPopup(null, $translateSection, false, updateUserSignedInStateAndEmail);
      }
    } else {
      console.log("onClickTranslateButton(): no authenticated user found");
      const $translateSection = document.getElementById("translateSectionId");
      ReactUtils.showUserSignInOrOutPopup(null, $translateSection, true, updateUserSignedInStateAndEmail);
    }
  }

  async function translateText() {
    if (textToTranslateValue) {
      await translate({text: textToTranslateValue}, Utils.TRANSLATE_TYPE.TEXT, textToTranslateValue);
    } else {
      setTranslatedTextValue("No text to translate ");
      setTranslatedTextareaColor('red');
    }
  }

  const translateDocument = async (docKey, translatedFileName) => {
   return await translate({[Utils.OBJECT_KEY_FOR_FILE_SERVER_KEY]: docKey, 
      [Utils.OBJECT_KEY_FOR_TRANSLATED_FILE_NAME]: translatedFileName
    }, Utils.TRANSLATE_TYPE.DOCUMENT, '');
  }

  const translateImage = async (imageTextToTranslate) => {
    setIsShowLoadingDimmer(true);
    return await translate({text: imageTextToTranslate}, Utils.TRANSLATE_TYPE.IMAGE, imageTextToTranslate);
  }

  const isShowingTranslateTextSection = () => {
    return activeMenuItem !== TRANSLATE_DOCUMENT_MENU_ID && activeMenuItem !== TRANSLATE_IMAGE_MENU_ID;
  }

  function clear() {
    setTextToTranslateValue('');
    setTranslatedTextValue('');
  }

  const [focusedTextarea, setFocusedTextarea] = useState(null);
  const handleTextareaFocus = (event) => {
    setFocusedTextarea(event.target);
  };

  const handleUserActionClick = async (action) => {
    const user = await AuthUtils.currentAuthenticatedUser();
    const $translateSection = document.getElementById("translateSectionId");
    if (!user) {
      ReactUtils.showUserSignInOrOutPopup(null, $translateSection, false, updateUserSignedInStateAndEmail);
      return;
    }

    const session = await AuthUtils.currentSession();
    if (!session?.getAccessToken()?.getJwtToken()) {
      ReactUtils.showUserSignInOrOutPopup(null, $translateSection, false, updateUserSignedInStateAndEmail);
      return;
    }

    if (!isUserSubscribed) {
      ReactUtils.showPurchasePremiumPopup(document.getElementById('textTotranslateFieldId'), userEmail);
      return;
    }

    try {
      let selectedText = focusedTextarea ? Utils.getSelectedStringInTextArea(focusedTextarea) : '';

      if (!selectedText) {
        setOtherOutputValue("No text selected, please select some text first.");
        return;
      }

      let requestData = {
        gptPrompt: getPromptFromAction(action, selectedText),
        requestType: 'gptPrompt',
        accessToken: session?.getAccessToken()?.getJwtToken()
      };
      
      setIsShowLoadingDimmer(true);
      const response = await Utils.awsClient.fetch(`${TRANSLATE_URL}/my-lambda/invocations`, 
        { body: JSON.stringify(requestData) });

      // console.log("response is for request ", requestData, response);
      setIsShowLoadingDimmer(false);

      const responseJson = await response.json();
      if (responseJson) {
        // console.log("gptResponse is", responseJson, responseJson.gptResponse, responseJson.subscriptionState);
        setOtherOutputValue(responseJson.gptResponse);
        Utils.setSubscriptionState(responseJson.subscriptionState);
      }
    } catch(error) {
      console.error("ask GPT text failed for ", action, error.message);
      setIsShowLoadingDimmer(false);
      setOtherOutputValue("Failed with: ", error.message);
    }
  };


  function getPromptFromAction(action, inputText) {
    if (action === USER_ACTION.TRANSLITERATE) {
      return "How to pronunce in details ```" + inputText + "``` in " + destinationLanguageValue + " language ";
    } else if (action === USER_ACTION.DICTIONARY) {
      return "Look up in the dictionary with all details for ```" + inputText + "```, return result and response in a easy to read format";
    } else if (action === USER_ACTION.SYNONYMS) {
      return "Get the Synonyms of ```" + inputText + "```";
    } else if (action === USER_ACTION.PRONUNCIATIONS) {
      return "Pronounce ```" + inputText + "``` in details";
    } else {
      console.error("Invalid user action", action);
      return null;
    }
  }

  return (<div>
    <meta charSet="utf-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/water.css@2/out/water.css"></link>
    <title>XTranslate</title>

    <style type="text/css">
      {Styles()}
    </style>

    {/* Your JavaScript script here */}
    {/* <script>
    </script> */}


    <div className={`ui vertical sidebar menu ${isMenuSidebarOpen ? 'visible' : ''}`} ref={sidebarMenuRef}>
      <a className="active item">Home</a>
      <a className="item" onClick={onScrollToProductsSection}>Products</a>
      {isUserSignedIn && (!isUserSubscribed) 
        && <a className="item" onClick={onScrollToPremiumSection}>Premium Plan</a>}
      <a className="item" href="/helpCenter">Help Center</a> 
      { !isUserSignedIn 
            && <a className="item" onClick={onClickSignup}>Start Free</a> }
      { !isUserSignedIn 
        && <a className="item" onClick={onClickLogin}>Login</a> }
      <a className="item" onClick={onMenuSidebarClick} >Close</a> 
    </div>

    {/* Component Section: Page Header and Menu */}
    <div className="ui container">
      <div className="ui large secondary menu" style={{paddingBottom: '20px'}}>
        <a className="toc item">
          <i id = "sideBarMenuId" className="sidebar icon" onClick={onMenuSidebarClick}></i>
        </a>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}>
          <img src={appLogo} alt="logo" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
          <div style={{ fontSize: '19px', display: 'inline', fontWeight: '600', fontFamily: "Arial, sans-serif" }}>XTranslateGPT</div>
        </div>

        <a className="active item">Home</a>
        <a className="item" onClick={onScrollToProductsSection}>Products</a>
        {isUserSignedIn && (!isUserSubscribed) && <a className="item" onClick={onScrollToPremiumSection}>Premium Plan</a>}
        <a className="item" href="/helpCenter">Help Center</a>

        { !isUserSignedIn 
          && <a className="ui button" style={{ marginLeft: 'auto', backgroundColor: 'rgb(167, 52, 222)', color: 'white'}} 
              onClick={onClickSignup}>Start Free</a> }
        { !isUserSignedIn 
          && <a className="ui button" style={{ marginLeft: '10px'}} onClick={onClickLogin}>Login</a> }
        { isUserSignedIn && <div style={{marginLeft: 'auto'}}>
              <button style={{ padding: '4px', marginTop: '5px', backgroundColor: 'white' }} onClick={onShowHistoryButtonClick}>
                <img src={historyImage} style={{ width: '18px' }} alt="" />
              </button>

            <Link to="/settings">
              <button id="settingsButtonId" style={{ padding: '4px', marginTop: '5px', backgroundColor: 'white' }}>
                <img id="settingsButtonImageId" src={settingsImage} style={{ width: '18px' }} alt="" />
              </button> 
            </Link>
          </div>
         }
      </div>
    </div>

    <div id="translateSectionId" style={{backgroundColor: '#F6F8FF', paddingTop: '20px', paddingBottom: '40px'}}>
      <div className="ui container">

      <div style={{marginTop: '2px' }}>
          <div className={`ui basic large button ${isShowingTranslateTextSection() ? 'active blue' : 'basic'}`} 
            id={TRANSLATE_TEXT_MENU_ID} onClick={(event) => handleMainMenuItemClick(event)} 
            style={{marginRight: '10px' }}><i className="language icon"></i>Text</div>
          <div className={`ui basic large button ${activeMenuItem === TRANSLATE_DOCUMENT_MENU_ID ? 'active blue' : ''}`} 
            id={TRANSLATE_DOCUMENT_MENU_ID} onClick={(event) => handleMainMenuItemClick(event)} 
            style={{marginRight: '10px' }}><i className="file alternate outline icon"></i>Documents (Beta)</div>
          <div className={`ui basic large button ${activeMenuItem === TRANSLATE_IMAGE_MENU_ID ? 'active blue' : ''}`} 
            id={TRANSLATE_IMAGE_MENU_ID} onClick={(event) => handleMainMenuItemClick(event)}>
              <i className="image outline icon"></i>Images</div>
      </div>        

        <div style={{marginTop: '8px' }} className="ui stackable equal height stackable grid">
          <div className="eight wide column">
            <select id="languageSelectionId" style={{ display: 'inline', paddingRight: '5px', marginLeft: '20px' }} 
              value={sourceLanguageValue} onChange={handleSourceLanguageChange} >
              {SOURCE_LANGUAGE_MAP.map((option) => (
                <option key={option.val} value={option.val}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
          <div className="eight wide column">
            <button className="ui mini basic button switch-language-button-margin" type="button" onClick={onSwitchLanguage}
              style={{ display: 'inline', boxShadow: 'none', padding: '10px, 6px'}}>
              <img src={switchArrowIcon} style={{ width: '18px' }} alt="switchLanguageButton" />
            </button>
            <select id="languageSelectionId" style={{ display: 'inline', paddingRight: '5px' }} 
              value={destinationLanguageValue} onChange={handleDestinationLanguageChange} >
              {LANGUAGE_MAP.map((option) => (
                <option key={option.val} value={option.val}>
                  {option.text}
                </option>
              ))}
            </select>
            { isShowingTranslateTextSection() && <button id="copyTranslationTextButtonId" type="button" onClick={onCopyTranslatedText}
              style={{ display: 'inline', padding: '2px', float: 'right', marginTop: '8px' }}>
              <img src={copyImage} style={{ width: '18px' }} alt="copyButton" />
            </button> }
          </div>
        </div>

        {/* Component Section: Translate text area element  */}
        { isShowingTranslateTextSection() && <div style={{display: 'flex', gap: '10px'}}>
          <div style={{ maxHeight: '80%', width: '50%', position: 'relative'}}>
            <textarea id="textTotranslateFieldId" className={isUserSubscribed ? "custom-textarea" : "custom-textarea-smaller"}
              style={{ fontSize: '16px', fontFamily: "Roboto,RobotoDraft,Helvetica,Arial,sans-serif", marginTop: '2px', marginBottom: '6px', 
                paddingRight: '30px', borderBottom: '36px solid #fff', backgroundColor: 'white', resize: 'none' }} // https://www.quora.com/In-CSS-how-does-one-add-white-space-to-the-bottom-of-a-textarea
              placeholder= {isUserSubscribed ? "Enter your text to translate, max 20000 characters" : "Enter your text to translate, max 3000 characters"}
              value={textToTranslateValue} onChange={handleTextToTranslateChange} onFocus={handleTextareaFocus}>
            </textarea>
            

            { (textToTranslateValue) && <button className="ui mini basic button" 
            onClick={clear} style={{ position: 'absolute', top: '10px', right: '4px', boxShadow: 'none', padding: '8px'}}>
            <img src={clearIcon} style={{ width: '16px' }} alt="clearButton" /></button> }

            { (textToTranslateValue) && <TextToSpeechComponent text={textToTranslateValue} language={sourceLanguageValue}></TextToSpeechComponent> }
          </div>
          {/* <div className="ui segment"> */}
          <div style={{ maxHeight: '80%', width: '50%', position: 'relative'}}>
            <textarea id="translatedTextId" className={isUserSubscribed ? "custom-textarea" : "custom-textarea-smaller"}
              style={{ fontSize: '16px', fontFamily: "Roboto,RobotoDraft,Helvetica,Arial,sans-serif", marginTop: '2px', marginBottom: '6px', 
                paddingRight: '30px', borderBottom: '36px solid #efefef', resize: 'none', color: translatedTextareaColor }} 
              value={translatedTextValue} onChange={handleTranslatedTextChange} onFocus={handleTextareaFocus}
            ></textarea>

            { (translatedTextValue) && <TextToSpeechComponent text={translatedTextValue} language={destinationLanguageValue}></TextToSpeechComponent> }
          </div>
        </div> }
        <div id="loadingDimmer" className={`ui inverted dimmer ${isShowLoadingDimmer ? 'active' : ''}`}>
          <div className="ui indeterminate text loader">Translating</div>
          <img src={rocketImage} style={{width: '24px', marginTop: '-24px' }} />
        </div>

        {/* Component Section: Translate service and translate tone  */}
        <div style={{marginTop: '10px'}}>
          <label style={{ color: 'gray', paddingRight: '5px' }}>Translator Service to use</label>
          <select id="translatorSelectionId" style={{ display: 'inline' }}
            value={translatorValue} onChange={handleTranslatorChange} >
            <option value={Utils.Translator_Service.GPT35}>{Utils.Translator_Service_Text.GPT35}</option>
            <option value={Utils.Translator_Service.GOOGLE}>{Utils.Translator_Service_Text.GOOGLE}</option>
            <option value={Utils.Translator_Service.MICROSOFT}>{Utils.Translator_Service_Text.MICROSOFT}</option>
          </select>
        </div>

        { (translatorValue === Utils.Translator_Service.GPT35) && 
        <div id="additionalContextSectionId" style={{ marginTop: '10px'}}>
          <div id="additionalContextLabelDivId" style={{ color: 'gray', paddingRight: '5px', paddingBottom: '10px'}}>
          Tone you want to translate to, such as Professional, Causal, Friendly, Formal etc</div>
          <input type="text" id="additionalContextInputId" list="additionalContextSuggestionsId" 
            value={translateTone} onChange={handleTranslateToneChange}/>
          <datalist id="additionalContextSuggestionsId">
            {Utils.TONE_SUGGESTIONS.map((option, index) => (<option key={index} value={option} />))}</datalist>
        </div> }

        { isShowingTranslateTextSection() && <div>
          <button id="translateButtonId" style={{ marginTop: '15px' }} className="translation-button" onClick={translateText}>
            <b>Translate</b>
          </button>
          <div className='user-actions-section'>
            <div className="ui buttons" >
              <button className="ui basic green button user-action-font-size"
                onClick={() => handleUserActionClick(USER_ACTION.TRANSLITERATE)}>Transliterate</button>
              <button className="ui basic green button user-action-font-size"
                 onClick={() => handleUserActionClick(USER_ACTION.DICTIONARY)}>Dictionary</button>
              <button className="ui basic green button user-action-font-size"
                onClick={() => handleUserActionClick(USER_ACTION.SYNONYMS)}>Synonyms</button>
              <button className="ui basic green button user-action-font-size"
                onClick={() => handleUserActionClick(USER_ACTION.PRONUNCIATIONS)}>Pronunciations</button>
            </div>
            <div style={{color: 'gray', marginTop: '2px'}}>{isUserSubscribed ? '(Transliterate, dictionary, synonyms, or pronunciations of the word you select)' 
              : 'Upgrade to use these Premium features!'}</div>
          </div>
          <div id="gptOutputTextId" contentEditable="true" 
            style={{ marginTop: '12px', minHeight: '2px', whiteSpace: 'pre-wrap', color: 'rgb(0, 119, 255)' }} 
          suppressContentEditableWarning={true} >{otherOutputValue}</div>
        </div> }
        

        {isUserSignedIn && (activeMenuItem === TRANSLATE_DOCUMENT_MENU_ID || activeMenuItem === TRANSLATE_IMAGE_MENU_ID) && 
          <FileUpload userName={userName} translateDocument={translateDocument} translateImage={translateImage} userEmail={userEmail} 
            isUserSubscribed={isUserSubscribed} isTranslateImage={activeMenuItem === TRANSLATE_IMAGE_MENU_ID}/> }
        
        { isUserSignedIn && (!isUserSubscribed) && <div>
          <div style={{marginTop: '40px', marginBottom: '10px'}}>You are on Free Trial version with one request limit every minute. We provide you 30 free translation and 8000 free characters Every Day to use. 
          Upgrade to our Premium service to enjoy unlimited usage, document translation, advanced features, long translation input and faster response!</div>
          <button id="upgradeButtonId" className="ui purple button large" 
            onClick={ (event) => { ReactUtils.showPurchasePremiumPopup(event.target, userEmail);  } }>
            <b>Upgrade to Premium Service</b> 
          </button>
          {/* <AdComponent></AdComponent> */}
          {/* XTranslateGPTAds */}
          {/* <ins className="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-client="ca-pub-6740757017179116"
              data-ad-slot="2658451373"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins>
          <script>
            {(adsbygoogle = window.adsbygoogle || []).push({})}
          </script>
          */}

        </div> }
      </div>

    </div>

    {/* Component Section: About Section */}
    <div style={{backgroundColor: '#eef0fa'}}>
      <div id="aboutSectionId" className="ui container" style={{paddingTop: '40px'}}>
        <h2 className="ui horizontal header divider">
          <a href="#">About XTranslateGPT</a>
        </h2>
        <br></br>
        <p style={{marginBottom: '30px', fontSize: '17px'}}>
          XTranslateGPT is the best translator powered by ChatGPT AI (used by 100+ million users), Google Translate™ service and Microsoft Translator. 
          It is fast, secure, natural and context based. It will help you translate and communicate across 100+ languages easily with the most languages supported in the market. 
          <br></br><br></br>It can automatically detects the language and translates the content to any selected language you want in seconds, just like your personal translator! You can also translate the language to different tones, e.g. professional, friendly, formal, funny! 
          <br></br><br></br>Whenever you want to translate or understand content in another language, just let XTranslateGPT do it for you. We have also developed multiple apps to assist you with translations easily anytime and anywhere! </p>
        {/* <h4 className="ui header">Why TranslateGPT?</h4> */}
      </div>

      <div id="productsSectionId" style={{paddingTop: '30px', paddingBottom: '80px'}} className="ui container" ref={productsSectionRef}>
        <h2 className="ui horizontal header">
          Apps
        </h2>
        <div className="ui stackable divided equal height grid">
              <div className="eight wide column">
                <p style={{marginTop: '30px', fontSize: '17px', fontWeight: '600'}}>XTranslateGPT for Google Docs, Slides, Sheets, and Gmail</p>
                <a href="https://workspace.google.com/marketplace/app/workspace_translator_with_gpt_translate/257171559362?pann=b"
                  target="_blank" aria-label="Get it from the Google Workspace Marketplace">
                  <img alt="Google Workspace Marketplace badge"
                    alt-text="Get it from the Google Workspace Marketplace"
                    src="https://workspace.google.com/static/img/marketplace/en/gwmBadge.svg?"
                    style={{ height: '68px' }}
                  />
                </a>
              </div>
              <div className="eight wide column">
                <div style={{fontSize: '17px', fontWeight: '600', paddingTop: '30px', display: 'flex', justifyContent: 'center'}}>XTranslateGPT for Chrome</div>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '15px'}}>
                  <button id="downloadChromeExtensionButtonId" className="ui button primary big basic" onClick={navigateToChromeExtension}>
                      <b>Download for free</b>
                  </button>
                </div>
              </div>
          </div>
      </div>
    </div>
    
    {/* Component Section: Premium Service Section */}
    { isUserSignedIn && (!isUserSubscribed) &&  <div id="premiumServiceSectionId" style={{backgroundColor: '#F8F8F8', paddingTop: '40px', paddingBottom: '30px'}} ref={premiumSectionRef}>
      <div className="ui container">
        <h2 className="ui horizontal header divider">
          <a href="#">Premium Service</a>
        </h2>
        <div id="NotUpgradedToPremiumService">
          <label style={{fontSize: '17px', fontWeight: '400', marginTop: '30px'}}>We provide Premium service with many great benefits and features. It offers: </label>
          <ul className="ui list">
            <li><b>200K characters/Day</b> for your translation. </li>
            <li><b>Accessible to advanced Premium features </b>(summarize, dictionary, pronunciations, synonyms, rewrite, detect langauges, explain, transliterate, history and more!!) to help you.</li>
            <li><b>8x Longer</b> translation input supported up to 20000 characters and 4000 words each time. </li>
            <li><b>Support images translation</b> without limit. </li>
            <li><b>Support Documents translation</b> with 10 files/month.</li>
            <li><b>Multiple apps supported</b>, Google Workspace, WebApp, Chrome Extension. iOS and Android are also coming!</li> 
            <li><b>Faster response</b> with dedicated GPT server. </li>
            <li><b>No ads</b>. </li>
            <li><b>Priority support</b> for your questions and requests. </li>
          </ul>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button id="monthlyPremiumButtonId"
              style={{ margin: '15px 0px 15px', width: '90%', maxWidth: '400px', minHeight: '40px', 
              backgroundColor: '#06ADEF', color: 'white' }}
              onClick={() => ReactUtils.onOpenMonthlySubscriptionLink(userEmail)}
            >
              <b>Monthly Premium for $5.99 (15% off)</b>
            </button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              id="yearlyPremiumButtonId"
              style={{ margin: '0px 0px 10px', width: '90%', maxWidth: '400px', minHeight: '40px', 
              backgroundColor: 'rgb(0, 119, 255)', color: 'white' }}
              onClick={() => ReactUtils.onOpenYearlySubscriptionLink(userEmail)}
            >
              <b>Yearly Premium for $44.99 (50% off)</b>
            </button>
          </div>
          <label style={{ paddingRight: '5px' }}>
            <br />
            <b>Note: Please make sure to use and put your Account Email <span style={{color: 'rgb(0, 119, 255)'}}>{userEmail}</span></b> when making the payment to get the Premium license for this email account.
            The Premium serivce will be linked to the email address you enter and use during the payment. <br /><br />
            After you finish the purchase, please click the "Already purchased and refresh" button below to reload the UI and get the subscription.
          </label>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button style={{ margin: '20px 15px 70px', width: '300px' }} className="ui green basic button"
              onClick={(event) => {ReactUtils.updateSubscriptionState(event.target); } }>
              Already Purchased and refresh
            </button>
            </div>
          <label style={{ paddingRight: '5px', color: 'gray' }}>
            Have more questions, or want to purchase a license for a group of people or the whole team/company?
            Please contact us at <a href="https://www.aiworksuite.com/contact-us">aiworksuite.com/contact-us</a>.
            We are happy to answer your questions or discuss related offers.
          </label>
        </div>
      </div>
    </div> }

    <Footer></Footer>

  </div>
  );
}

const Styles = () => (
  <style>
    {`
      body {
        max-width: 100%; !important;
        padding: 0px;
      }

      #menu li {
        display: inline-block;
        margin-right:50px;
      }

      .centered {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .translation-button {
        background-color: rgb(0, 119, 255);
        color: white;
        min-width: 150px;
        height: 40px;
      }

      .hidden.menu {
        display: none;
      }

      .masthead.segment {
        min-height: 700px;
        padding: 1em 0em;
      }
      .masthead .logo.item img {
        margin-right: 1em;
      }
      .masthead .ui.menu .ui.button {
        margin-left: 0.5em;
      }
      .masthead h1.ui.header {
        margin-top: 3em;
        margin-bottom: 0em;
        font-size: 4em;
        font-weight: normal;
      }
      .masthead h2 {
        font-size: 1.7em;
        font-weight: normal;
      }

      .ui.vertical.stripe {
        padding: 8em 0em;
      }
      .ui.vertical.stripe h3 {
        font-size: 2em;
      }
      .ui.vertical.stripe .button + h3,
      .ui.vertical.stripe p + h3 {
        margin-top: 3em;
      }
      .ui.vertical.stripe .floated.image {
        clear: both;
      }
      .ui.vertical.stripe p {
        font-size: 1.33em;
      }
      .ui.vertical.stripe .horizontal.divider {
        margin: 3em 0em;
      }

      .quote.stripe.segment {
        padding: 0em;
      }
      .quote.stripe.segment .grid .column {
        padding-top: 5em;
        padding-bottom: 5em;
      }

      .footer.segment {
        padding: 5em 0em;
      }

      .secondary.pointing.menu .toc.item {
        display: none;
      }

      .custom-textarea {
        height: 500px !important; /* Adjust the value as needed */
      }

      .switch-language-button-margin {
        margin-left: -30px !important;
        margin-right: 20px !important;
        margin-bottom: -10px !important;
      }

      .user-actions-section {
        display: inline;
        float: right;
        margin-top: 8px;
      }

      .user-action-font-size {
        font-size: 1rem;
      }

      .custom-textarea-smaller {
        height: 440px !important; /* Adjust the value as needed */
      }

      @media only screen and (max-width: 700px) {
        .ui.fixed.menu {
          display: none !important;
        }
        .secondary.large.menu .item,
        .secondary.large.menu .button,
        .secondary.large.menu .menu {
          display: none;
        }
        .secondary.large.menu .toc.item {
          display: block;
        }
        .masthead.segment {
          min-height: 350px;
        }
        .masthead h1.ui.header {
          font-size: 2em;
          margin-top: 1.5em;
        }
        .masthead h2 {
          margin-top: 0.5em;
          font-size: 1.5em;
        }

        .custom-textarea {
          /* Default height for larger screens */
          height: 250px !important;
        }

        .custom-textarea-smaller {
          /* Default height for larger screens */
          height: 250px !important;
        }

        .switch-language-button-margin {
          margin-left: 0px !important;
          margin-right: 0px !important;
          margin-bottom: -10px;
        }

        .user-actions-section {
          display: block;
          float: left;
          margin-top: 10px;
        }

        .user-action-font-size {
          font-size: 0.7rem !important;
        }
      }
    `}
  </style>
);