import { AwsClient } from 'aws4fetch'
import * as AuthUtils from './AuthUtils.js';
import { LANGUAGE_MAP, AUTO_DETECT_LANGUAGE_VALUE } from './LanguageMap.js';

const USER_SUBSCRIPTION_URL = "https://qt36ssciww2qnwxu556nkozlmq0nvuns.lambda-url.us-west-2.on.aws";
//const TRANSLATE_URL_TEST = "https://59lb2r4nzh.execute-api.us-west-2.amazonaws.com/dev";
const AWS_LAMBDA_FUNCTION_ACCESS_KEY = "AKIART2LN3C25LZDUTDG";
const AWS_LAMBDA_FUNCTION_SECRET_KEY = "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";
const AWS_REGION = "us-west-2";
const AWS_SERVICE = "lambda"
export const awsClient = new AwsClient({ accessKeyId: AWS_LAMBDA_FUNCTION_ACCESS_KEY, 
  secretAccessKey: AWS_LAMBDA_FUNCTION_SECRET_KEY, region: AWS_REGION, service: AWS_SERVICE});

export const Translator_Service = {
  GPT35: 'gpt35',
  GOOGLE: 'google',
  MICROSOFT: 'microsoft'
};

export const Translator_Service_Text = {
  GPT35: "GPT Translator", 
  GOOGLE: "Google Translate",
  MICROSOFT: "Microsoft Translator"
}

export const TRANSLATE_TYPE = {
  TEXT: 'Text', 
  DOCUMENT: 'Document',
  IMAGE: 'Image'
}

export const TTS_VOICE_SPEED = {
  NORMAL: '1', 
  SLOW: '0.8',
  SLOWER: '0.5',
  FAST: '2',
}
export const DEFAULT_TTS_VOICE_SPEED = TTS_VOICE_SPEED.NORMAL;

export const OBJECT_KEY_FOR_FILE_SERVER_KEY = "fileBucketKey";
export const OBJECT_KEY_FOR_FILE_URL = "fileUrl";
export const OBJECT_KEY_FOR_TRANSLATED_FILE_NAME = "translatedFileName";
export const OBJECT_KEY_FOR_TRANSLATED_IMAGE_TEXT = "translatedImageText";

export const TONE_SUGGESTIONS = ["None", "Professional", "Friendly", "Causal", "Formal", 
  "Enthusiastic", "Funny", "Positive", "Urgent", "Informational", "Very Professional", "Very Friendly", "Very Positive"];

export const DEFAULT_AUTO_DETECT_SOURCE_LANGUAGE = AUTO_DETECT_LANGUAGE_VALUE;
export const DEFAULT_DESTINATION_LANGUAGE = "en";
export const DEFAULT_TRANSLATOR = Translator_Service.GPT35;  
export const DEFAULT_TRANSLATE_TONE = "";
export const default_i18n = 'en'

export const SETTINGS_LANGUAGE_TRANSLATE_TO = "settings_targetLanguage";
export const SETTINGS_LANGUAGE_SOURCE_TRANSLATE_FROM = "settings_sourceLanguage"
export const SETTINGS_TRANSLATOR_SELECTED = "settings_translatorSelected";
export const SETTINGS_TRANSLATE_TONE = "settings_translateTone";
export const SETTINGS_TTS_VOICE_SPEED = "settings_ttsVoiceSpeed";

export const LAST_TRANSLATE_TIMESTAMP = "lastTranslateTimestamp";
export const TRANSLATE_REQUEST_MADE_TODAY = "translateRequestMadeToday";
export const TOTAL_TRANSLATE_REQUEST_MADE = "totalTranslateRequestMade";
export const TRANSLATE_CHARS_MADE_TODAY = "translateCharsMadeToday";
export const SUBSCRIPTION_STATE = "subscriptionState";

export const SubscriptionState = {
  NONE: 'None',
  PREMIUM: 'Premium',
  ENTERPRISE: 'Enterprise'
};

export async function getUserAccesstoken() {
  const user = await AuthUtils.currentAuthenticatedUser();
  if (user) {
    const session = await AuthUtils.currentSession();
    return session?.getAccessToken()?.getJwtToken();
  }

  return null;
}

export function isUserSubscribed(subscriptionState) {
  return subscriptionState === SubscriptionState.PREMIUM
    || subscriptionState === SubscriptionState.ENTERPRISE;
}

export function setLocalStorageProperty(key, value) {
  localStorage.setItem(key, value);
}

export function getLocalStorageProperty(key) {
  return localStorage.getItem(key);
}

export function setLastTranslateTimeStamp() {
  setLocalStorageProperty(LAST_TRANSLATE_TIMESTAMP, Date.now());
}

export function getLastTranslateTimeStamp() {
  return getLocalStorageProperty(LAST_TRANSLATE_TIMESTAMP);
}

export function setSubscriptionState(subscriptionState) {
  const expirationMinutes = (isUserSubscribed(subscriptionState) ? 1440 : 60);
  const expirationTime = new Date().getTime() + expirationMinutes * 60 * 1000;
  // Store the cache value along with the expiration time
  const dataWithExpiration = { value: subscriptionState, expiration: expirationTime };
  //console.log("setSubscriptionState()", dataWithExpiration);
  setLocalStorageProperty(SUBSCRIPTION_STATE, dataWithExpiration);
}

export async function getSubscriptionState() {
  const userAccessToken = await getUserAccesstoken();
  if (!userAccessToken) {
    return SubscriptionState.NONE;
  }
  const cachedSubscriptionState = getLocalStorageProperty(SUBSCRIPTION_STATE);
  //console.log("getSubscriptionState()", cachedSubscriptionState[SUBSCRIPTION_STATE].expiration, 

  // Check if the cache value exists and is not expired
  if (cachedSubscriptionState && cachedSubscriptionState[SUBSCRIPTION_STATE]?.expiration 
    && cachedSubscriptionState[SUBSCRIPTION_STATE].expiration >= new Date().getTime()) {
    return cachedSubscriptionState[SUBSCRIPTION_STATE].value;
  } else {
    return await fetchSubscriptionStateFromBackend(userAccessToken);
  }
}

export async function fetchSubscriptionStateFromBackend(userAccessToken) {
  const requestData = {
    accessToken: userAccessToken
  };

  const response = await awsClient.fetch(`${USER_SUBSCRIPTION_URL}/my-lambda/invocations`, 
    { body: JSON.stringify(requestData) });
  const subscriptionResponse = await response.json();
  const subscriptionState = subscriptionResponse.subscriptionState;
  setSubscriptionState(subscriptionState);

  return subscriptionState;
}

export async function getIsExcceedRequestLimitEveryMin() {
  if (getIsSkipExcceedRequestLimitEveryMin()) {
    return false;
  }

  const subscriptionState = await getSubscriptionState();
  if (isUserSubscribed(subscriptionState)) {
    return false;
  }

  const requestTodayJson = getLocalStorageProperty(TRANSLATE_REQUEST_MADE_TODAY);
  var requestTodayMap = requestTodayJson ? JSON.parse(requestTodayJson) : {};
  var currentDateString = new Date().toLocaleDateString();
  if (requestTodayMap[currentDateString] && requestTodayMap[currentDateString] > 3) {
    const lastTranslationTimestamp = getLastTranslateTimeStamp();
    if (lastTranslationTimestamp && (Date.now() - lastTranslationTimestamp) < 20000) {
      return true;
    }
  }

  return false;
}

export function getIfReachDailyRequestLimit(subscribeState) {
  // Assuming USER_PROPERTY_GPT_REQUEST_TODAY is a variable holding the key for the user property
  // Initialize an empty map if no existing map is found
  const requestTodayJson = getLocalStorageProperty(TRANSLATE_REQUEST_MADE_TODAY);
  var requestTodayMap = requestTodayJson ? JSON.parse(requestTodayJson) : {};
  var currentDateString = new Date().toLocaleDateString(); 
  
  //console.log("requestToday Map", requestTodayMap);
  if (requestTodayMap[currentDateString]) {
    if (subscribeState === SubscriptionState.ENTERPRISE) {
      return requestTodayMap[currentDateString] > 2000;
    } else if (subscribeState === SubscriptionState.PREMIUM) {
      return requestTodayMap[currentDateString] > 2000;
    } else {
      return requestTodayMap[currentDateString] > 30;
    } 
  }

  return false;
}

export function getIsSkipExcceedRequestLimitEveryMin() {
  const requestTodayJson = getLocalStorageProperty(TRANSLATE_REQUEST_MADE_TODAY);
  var requestTodayMap = requestTodayJson ? JSON.parse(requestTodayJson) : {};
  var currentDateString = new Date().toLocaleDateString(); 

  return requestTodayMap[currentDateString] < 5;
}
  
export async function increaseDailyRequest() {
  // Initialize an empty map if no existing map is found
  const requestTodayJson = getLocalStorageProperty(TRANSLATE_REQUEST_MADE_TODAY);
  var requestTodayMap = requestTodayJson ? JSON.parse(requestTodayJson) : {};
  const currentDateString = new Date().toLocaleDateString(); 

  if (requestTodayMap[currentDateString]) {
    requestTodayMap[currentDateString] = requestTodayMap[currentDateString] + 1;
  } else {
    // The first request of today, will clear the map before.
    requestTodayMap = {};
    requestTodayMap[currentDateString] = 1;
  }

  // console.log("Daily Translation Request made", requestTodayMap[currentDateString]);

  var updatedrequestJson = JSON.stringify(requestTodayMap);
  setLocalStorageProperty(TRANSLATE_REQUEST_MADE_TODAY, updatedrequestJson);
}

export function increaseDailyTranslatedCharsMade(charsLength) {
  const translatedCharsJson = getLocalStorageProperty(TRANSLATE_CHARS_MADE_TODAY);
  // Initialize an empty map if no existing map is found
  var translatedCharsMap = translatedCharsJson ? JSON.parse(translatedCharsJson) : {};
  const currentDateString = new Date().toLocaleDateString(); 

  if (translatedCharsMap[currentDateString]) {
    translatedCharsMap[currentDateString] = translatedCharsMap[currentDateString] + charsLength;
  } else {
    // The first request of today, will clear the map before.
    translatedCharsMap = {};
    translatedCharsMap[currentDateString] = charsLength;
  }

  //console.log("Daily Translation chars made", translatedCharsMap[currentDateString], charsLength);

  var updatedrequestJson = JSON.stringify(translatedCharsMap);
  setLocalStorageProperty(TRANSLATE_CHARS_MADE_TODAY, updatedrequestJson);
}

export function getIfReachDailyTranslatedCharsLimit(subscribeState) {
  if ((subscribeState === SubscriptionState.ENTERPRISE) || (subscribeState === SubscriptionState.PREMIUM)) {
    return false;
  }

  const translatedCharsJson = getLocalStorageProperty(TRANSLATE_CHARS_MADE_TODAY);
  var translatedCharsMap = translatedCharsJson ? JSON.parse(translatedCharsJson) : {};
  const currentDateString = new Date().toLocaleDateString(); 

  return (translatedCharsMap[currentDateString] ?? 0) > 8500;
}

export function showLoadingDimmer($dimmer) {
  $dimmer?.classList.add("active");
}

// export function to hide the dimmer
export function hideLoadingDimmer($dimmer) {
  $dimmer?.classList.remove("active");
}

export function addSelectOptionToElement(selectElement, value, text, previousSelected) {
  const newOption = document.createElement("option");
  newOption.value = value; 
  newOption.text = text; 

  selectElement.appendChild(newOption);

  if (previousSelected === value) {
    selectElement.value = value;
  }
}

export function initTranslateLanguageElement(languageTranslatedTo, $translateLanguageSelection) {
  //$translateLanguageSelection.innerHTML = '';
  LANGUAGE_MAP.forEach((language) => {
    addSelectOptionToElement($translateLanguageSelection, language.val, language.text, languageTranslatedTo);
  });
}

export function hideHTMLElement($element) {
  if ($element) {
    $element.style.display = "none";
  }
}

export function showHTMLElement($element) {
  if ($element) {
    $element.style.display = "block";
  }
}

export function getSelectedStringInTextArea($textAreaElement) {
  const startIndex = $textAreaElement.selectionStart;
  const endIndex = $textAreaElement.selectionEnd;
  //console.log("startIndex, endIndex, value", startIndex, endIndex, 
  //  $textAreaElement.value, $textAreaElement.value.substring(startIndex, endIndex));
  return $textAreaElement.value.substring(startIndex, endIndex);
}

export function addToTranslationHistory(originalText, translatedText) {
  const newTranslation = {
    originalText: originalText,
    translatedText: translatedText,
    timestamp: new Date().toLocaleString(),
  };

  const existingTranslations = JSON.parse(localStorage.getItem('translationsHistory')) || [];

  existingTranslations.push(newTranslation);

  localStorage.setItem('translationsHistory', JSON.stringify(existingTranslations));
}

// return translation history as an array
export function getTranslationHistory() {
  // Retrieve the translations from localStorage
  const translations = JSON.parse(localStorage.getItem('translationsHistory')) || [];
  return translations;
}

export function clearTranslationHistory() {
  localStorage.removeItem('translationsHistory');
}