import React from 'react';
import appLogo from "../images/translateGPT_128.png";
import "semantic-ui-css/semantic.min.css"
import Footer from './Footer';
import Header from './Header';
import './CommonStyles.css';

const PrivacyPolicyPage = () => {

  return (
    <div>
      <meta charSet="utf-8" />
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/water.css@2/out/water.css"></link>
      <title>TranslateGPT</title>

      <div className="ui container">
        <Header></Header>

        <div style={{ marginTop: '50px', marginBottom: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
          <h1 className='common-font' style={{color: 'rgb(0, 122, 255)'}}>Privacy Policy</h1>
        </div>

        <p className='medium-text'>
        XTranslate and TranslateGPT (ZQ Labs Inc.) are committed to strong and transparent privacy practices. This Privacy Policy describes the information collected by XTranslate and TranslateGPT (ZQ Labs Inc.), and the ways we collect, store, use, and manage the information, including personal information that you provide or we collect in using any of our websites, apps provided on a mobile platform, or other services operated by us. <br></br>
        <br></br>
        Please read this Privacy Policy carefully. By using our website, Google addons, mobile apps, and/or other services we provide, you agree to everything in this Privacy Policy and the processing of your data, including your personal information, in the manner provided in this Policy. If you don’t agree to this Privacy Policy and applicable User Agreements, please exit, and do not access or use, our products or Service. 
        Please also note that the scope of this Policy is limited to information collected or received by us through your use of our services. 
        We are not responsible for the actions of third party people or companies, the content of their sites, the use of information you provide to them, or any products or services they may offer. 
        As used herein, the term “use” shall mean use, access, play, install, sign into, connect with, download, visit or browse our Sites or Products. 
        </p>

        <h3 className='common-font' style={{ marginTop: '40px'}}>Information we DO NOT collect:</h3>
        <p className='medium-text'>
        * In XTranslate and TranslateGPT, the contents you ask to translate are transferred to GPT-3.5, Google Translate and Microsoft Translator for translating and generated back to the user. XTranslate and TranslateGPT (ZQ Labs, Inc.) will not store these.  
        </p>

        <h3 className='common-font' style={{ marginTop: '40px'}}>Information We Collect:</h3>
        <ul className="ui list medium-text" style={{ fontSize: '17px' }}>
          <li>Account information, such as your name, email address, and phone number, is collected when you create an account with us. </li>
          <li>We may also collect usage data, such as your browsing history, preferences and settings, to improve the performance and functionality of our service.</li>
        </ul>

        <h3 className='common-font' style={{ marginTop: '40px'}}>Information We Collect:</h3>
        <p className='medium-text'>
        * We use the information we collect to provide, maintain, and improve our service, as well as to personalize your experience and communicate with you.
        </p>

        <h3 className='common-font' style={{ marginTop: '40px'}}>Use of Information:</h3>
        <p className='medium-text'>
        * We use the information we collect to provide, maintain, and improve our service, as well as to personalize your experience and communicate with you.
        </p>

        <h3 className='common-font' style={{ marginTop: '40px'}}>Information Sharing:</h3>
        <ul className="ui list common-font" style={{ fontSize: '17px' }}>
          <li>We will share your request commands and the related contents data to OpenAI APIs (GPT-3.5 ChatGPT), Google Translate, Microsoft Translator to perform related tasks based on your requests. Here is the latest data policy for OpenAI API : https://openai.com/policies/api-data-usage-policies . Based on OpenAI, your data submitted will not be used to train or improve their models, unless you explicitly decide to share your data with them for this purpose. </li>
          <li>We may share your information with third-party service providers, such as our cloud hosting provider or content creation service, for the purposes of providing, maintaining and improving our service.</li>
          <li>We will not share or disclose your information to any other third party, unless we are required to do so by law or in response to a valid request from a law enforcement or government agency, or as otherwise necessary to protect our rights or interests.</li>
        </ul>

        <h3 className='common-font' style={{ marginTop: '40px'}}>Security:</h3>
        <p className='medium-text'>
        We value your trust in using our service, thus we are striving to use commercially acceptable means of protecting it. We use appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data.
        </p>

        <h3 className='common-font' style={{ marginTop: '40px'}}>Changes to This Privacy Policy:</h3>
        <p className='medium-text' style={{ marginBottom: '60px'}}>
        We reserve the right to change this Policy at any time. We may update this privacy policy from time to time, and we will post the updated policy on our website. Your continued use of the service after we post any such changes to the policy will indicate your acceptance of such changes. <br></br><br></br>If you have any questions about our privacy policy or would like to request access to or deletion of your personal information, please contact us. Please note that XTranslate and TranslateGPT is not responsible for the privacy policies or practices of any third-party websites or services linked to or from our service. We encourage you to read the privacy policies of any third-party websites or services that you visit.
        </p>


        <Footer></Footer>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;