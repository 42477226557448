import React, { useState, useRef } from 'react';
import "semantic-ui-css/semantic.min.css"
import AWS from "aws-sdk";
import * as Utils from '../utils/Utils.js';
import * as ReactUtils from "../utils/ReactUtils.js";
import { createWorker } from 'tesseract.js';

const BUCKET_NAME = 'xtranslate';
const CurrentStage = {
  ToUpload: 'ToUpload',
  ToTranslate: 'ToTranslate',
  ToDownloadTranslation: 'ToDownloadTranslation'
};

const UPLOAD_DOCUMENT_FAIL_MESSAGE = "Upload document fails ";
const DELETE_FILE_FAIL_MESSAGE = "Delete document fails ";
const TRANSLATE_DOCUMENT_FAIL_MESSAGE = "Translate document fails ";


const FileUpload = (props) => {
  const [isUserSubscribed, setIsUserSubscribed] = useState(props.isUserSubscribed); // useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [errorOutputValue, setErrorOutputValue] = useState("");
  const [isShowUploadingDimmer, setIsShowUploadingDimmer] = useState(false);
  const isTranslatingImage = props.isTranslateImage;

  const fileInputRef = useRef(null); 
  const [currentStage, setCurrentStage] = useState(CurrentStage.ToUpload);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [translatedFile, setTranslatedFile] = useState(null);
  const [translatedImageText, setTranslatedImageText] = useState('');

  AWS.config.update({
    accessKeyId:  "AKIART2LN3C25LZDUTDG", 
    secretAccessKey: "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",
  });
  const s3 = new AWS.S3({params: { Bucket:  BUCKET_NAME}, region: "us-west-2"});
  let ocrWorker = null;

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleFiles = async (files) => {
    const subscriptionState = await Utils.getSubscriptionState(); // Utils.SubscriptionState.PREMIUM
    setIsUserSubscribed(Utils.isUserSubscribed(subscriptionState));
    // setIsUserSubscribed(true);

    if (Utils.isUserSubscribed(subscriptionState)) {
      if (isTranslatingImage) {
        try {
          setIsShowUploadingDimmer(true);
          if (!ocrWorker) {
            ocrWorker = await createWorker('eng');
          }
          
          const { data: { text } } = await ocrWorker.recognize(files[0]);
          console.log("text from ocr", text);
          setIsShowUploadingDimmer(false);
          const translatedImageText = await props.translateImage(text);
          if (translatedImageText && translatedImageText.errorMessage) {
            setErrorOutputValue(translatedImageText.errorMessage);
            setTranslatedImageText("");
          } else {
            setTranslatedImageText(translatedImageText[Utils.OBJECT_KEY_FOR_TRANSLATED_IMAGE_TEXT]);
            setErrorOutputValue("");
          }
        } catch (error) {
          console.error('Error performing OCR:', error);
        } finally {
          await ocrWorker.terminate();
          setIsShowUploadingDimmer(false);
        }
      } else {
        for (const file of files) {
          console.log('File selected:', file.name);
          uploadFile(file);
          break;
        }
      }
    } else {
      ReactUtils.showPurchasePremiumPopup(document.getElementById('uploadDocumentId'), props.userEmail);
    }
  };

  const uploadFile = async (file) => {
    if (file) {
      setIsShowUploadingDimmer(true);
      const uploadParams = {
        Key:  props.userName + file.name,
        Body: file,
      };

      console.log("File uploaded key is", props.userName + file.name);

      // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#upload-property
      s3.upload(uploadParams, (err, data) => {
        setIsShowUploadingDimmer(false);
        if (err) {
          console.error('Error uploading file:', err);
          setErrorOutputValue(UPLOAD_DOCUMENT_FAIL_MESSAGE + err.message);
        } else if (data?.Location && data?.Key){
          console.log('File uploaded succeeded:', data);
          setCurrentStage(CurrentStage.ToTranslate)
          setUploadedFile({name: file.name, [Utils.OBJECT_KEY_FOR_FILE_URL]: data.Location, 
            [Utils.OBJECT_KEY_FOR_FILE_SERVER_KEY]: data.Key })
          setErrorOutputValue("");
        } else {
          console.error("Upload file url is null", err);
          setErrorOutputValue(UPLOAD_DOCUMENT_FAIL_MESSAGE + ": Uploaded file url is null");
        }
      });
    } else {
      setErrorOutputValue(UPLOAD_DOCUMENT_FAIL_MESSAGE + ": document is null");
    }
  }

  const handleBrowseAndSelectFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteUploadedFileButtonClick = () => {
    if (uploadedFile) {
      var params = {
        Bucket: BUCKET_NAME, 
        Key: uploadedFile[Utils.OBJECT_KEY_FOR_FILE_SERVER_KEY]
      };
      console.log('handleDeleteUploadedFileButtonClick', uploadedFile[Utils.OBJECT_KEY_FOR_FILE_SERVER_KEY]);
      s3.deleteObject(params, function(err, data) {
        if (err) {
          console.error("delete uploaded file fails", err, err.stack);
          setErrorOutputValue(DELETE_FILE_FAIL_MESSAGE + err.message);
        } else {
          console.log("delete uploaded file succeeded", data);
          setCurrentStage(CurrentStage.ToUpload);
          setUploadedFile(null);
        }
      });
    }

    setErrorOutputValue("");
  }

  const handleDeleteTranslatedFileButtonClick = () => {
    if (translatedFile) {
      var params = {
        Bucket: BUCKET_NAME, 
        Key: translatedFile[Utils.OBJECT_KEY_FOR_FILE_SERVER_KEY]
      };
      s3.deleteObject(params, function(err, data) {
        if (err) {
          console.error("delete translated file fails ", err, err.stack);
          setErrorOutputValue(DELETE_FILE_FAIL_MESSAGE + err.message);
        } else {
          console.log("delete translated file succeeded ", data);
          setCurrentStage(CurrentStage.ToUpload);
          setTranslatedFile(null);
        }
      });
    }

    handleDeleteUploadedFileButtonClick();
  }

  async function translateDocument() {
    const translatedFileName = ("translated_" + uploadedFile.name).replace(/\.(pdf|docx)$/, '.txt'); // convert to txt file
    const translatedFile = await props.translateDocument(uploadedFile[Utils.OBJECT_KEY_FOR_FILE_SERVER_KEY], translatedFileName);

    if (translatedFile && !translatedFile.errorMessage) {
      setCurrentStage(CurrentStage.ToDownloadTranslation);
      setTranslatedFile(translatedFile);
      setErrorOutputValue("");
    } else {
      setErrorOutputValue(translatedFile?.errorMessage);
    }
  }

  const handleDownloadTranslationClick = () => {
    const params = {
      Bucket: BUCKET_NAME,
      Key: translatedFile[Utils.OBJECT_KEY_FOR_FILE_SERVER_KEY],
      Expires: 1200, // Set the expiration time for the presigned URL (in seconds)
    };

    // console.log("Download params", params);
    s3.getSignedUrl('getObject', params, function (err, signedTranslatedFileUrl) {
      if (err) {
        console.error("download translated file failed ", err, signedTranslatedFileUrl);
      } else {
        const link = document.createElement('a');
        link.href = signedTranslatedFileUrl;
        link.setAttribute('download', '');
        //link.download = "translated_" + uploadedFile.name; // Set the desired file name
    
        document.body.appendChild(link);
        // Trigger a click event on the anchor to start the download
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  return (
    <div>
      <style type="text/css">
        {Styles()}
      </style>

      {currentStage === CurrentStage.ToUpload && <div id="uploadDocumentId" className="ui two column divided stackable center aligned grid">
        {/* <div class="ui vertical divider">Or</div> */}
        <div className="middle aligned row" style={{ padding: '40px 0px 20px 0px'}}>
          <div className="column">
            <div onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}
              className={`drop-zone ${isDragging ? 'dragover' : ''}`}>
              <i className="upload cloud icon primary-color huge"></i>
              <p className="primary-color" style={{ marginTop: '10px', fontSize: '17px' }}><b>Drag & Drop file here or click to select</b></p>
              <input type="file" id="file-input" ref={fileInputRef} className="file-input"
                accept={isTranslatingImage? ".bmp, .jpg, .png, .pbm, .webp" : ".pdf, .xlsx, .docx, .pptx, .txt"} // https://stackoverflow.com/questions/34390747/how-can-i-validate-for-only-csv-file-uploads-using-the-pattern-attribute-using-h
                onChange={(e) => handleFiles(e.target.files)}
              />
            </div>
          </div>
          <div className="column">
            <div><b>Or choose a file</b></div>
            <button style={{ marginTop: '15px' }} className="primary-button" onClick={handleBrowseAndSelectFileButtonClick}
            disabled={!isUserSubscribed}>
              <b>Browse and select from your computer</b>
            </button>
          </div>
        </div>
        {!isUserSubscribed && <div style={{color: 'red', fontSize: '17px'}}>Upgrade to Premium service to start translating documents and images!</div>}
        {isUserSubscribed && (isTranslatingImage 
          ? <div style={{ color: 'gray'}}>Supported file types: .bmp, .jpg, .png, .pbm, .webp</div>
          : <div style={{ color: 'gray'}}>Supported file types: .txt, .pdf, .docx, and up to 20 pages.</div> )}
      </div>}

      <div id="uploadingDimmer" className={`ui inverted dimmer ${isShowUploadingDimmer ? 'active' : ''}`}>
          <div className="ui indeterminate text loader">{isTranslatingImage ? 'Recognizing text' : 'Uploading'}</div>
      </div>

      { (currentStage === CurrentStage.ToTranslate || currentStage === CurrentStage.ToDownloadTranslation) && 
      <div style={{height: '200px', width: '100%', display: 'flex', // Use flexbox for centering
        alignItems: 'center', justifyContent: 'center'}}>
        <div style={{ padding: '30px 15px 30px 30px', display: 'inline-block', // Make the background color only as wide as the text
          //border: '2px solid #000', // Add a 2px black border around the text
          borderRadius: '10px', backgroundColor: 'rgb(232,240,254)'}}>
          <i className="file alternate outline icon big" style={{color: 'rgb(138,180,248)', marginRight: '10px'}}></i>
            {uploadedFile?.name}
          <button className="ui icon button circular" 
            style={{backgroundColor: 'rgb(232,240,254)', marginLeft: '70px'}} onClick={handleDeleteUploadedFileButtonClick}>
            <i className="close icon"></i>
          </button>
        </div>
      </div> }

      {currentStage === CurrentStage.ToTranslate && <div>
        <button id="translateDocumentButtonId" style={{ marginTop: '0px' }} className="translation-button" onClick={translateDocument}>
          <b>Translate document</b>
        </button>
        <button id="deleteFileButtonId" className="" onClick={handleDeleteUploadedFileButtonClick}>Delete file</button>
      </div>}

      {currentStage === CurrentStage.ToDownloadTranslation && <div>
        <button id="downloadDocumentButtonId" style={{ marginTop: '0px' }} className="translation-button" onClick={handleDownloadTranslationClick}>
          <i className="cloud download icon"></i><b>Download translation</b>
        </button>
        <button id="deleteTranslatedFileButtonId" className="" onClick={handleDeleteTranslatedFileButtonClick}>
          <i className="trash alternate outline icon"></i>Delete file and upload another to translate
        </button>
      </div>}

      <div id="errorOutputTextId" style={{ minHeight: '20px', color: 'red' }}>{errorOutputValue}</div>
      <div id="translatedImageTextId" style={{ minHeight: '20px', color: 'rgb(0, 119, 255)' }}>{translatedImageText}</div>
    </div>
  );
};

const Styles = () => (
  <style>
    {`
      .drop-zone {
        border: 2px dashed #ccc;
        padding: 140px 80px;
        text-align: center;
      }

      .primary-color {
        color: rgb(0, 119, 255);
      }

      .primary-button {
        background-color: rgb(0, 119, 255);
        color: white;
        min-width: 150px;
        height: 40px;
      }
      
      .dragover {
        background-color: #f0f0f0;
      }
      
      .file-input {
        display: none;
      }

    `}
  </style>
);

export default FileUpload;
