import React from 'react';

export default class AdComponent extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
        <ins className="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-client="ca-pub-6740757017179116"
              data-adtest="on"
              data-ad-slot="2658451373"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins>
    );
  }
}