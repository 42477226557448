import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';

config["oauth"] = {
  "domain": "xtranslate.auth.us-west-2.amazoncognito.com",
  "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
  ],
  "redirectSignIn": "https://www.xtranslate.ai/", //"http://localhost:3000/", //"https://www.workgpt.aiworksuite.com/", //  "chrome-extension://dnffnnhonjgemhiegeenagikblhnfhaa/options/options.html",,  
  "redirectSignOut": "https://www.xtranslate.ai/", //"http://localhost:3000/", // "https://www.workgpt.aiworksuite.com/", // "chrome-extension://dnffnnhonjgemhiegeenagikblhnfhaa/options/options.html",, 
  "responseType": "code"
};

console.log("AWS export is", config);

Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();