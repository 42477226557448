import React, { Component } from 'react';
import * as Utils from '../utils/Utils.js';
import speakerIcon from "../images/speaker.png";
import stopIcon from "../images/stop.png";

// https://appmakers.dev/bcp-47-language-codes-list/
const langCode2TTSLang = {
  'ar': 'ar-001',
  'bg': 'bg-BG',
  'ca': 'ca-ES',
  'cs': 'cs-CZ',
  'da': 'da-DK',
  'de': 'de-DE',
  'el': 'el-GR',
  'en': 'en-US',
  'es': 'es-ES',
  'fi': 'fi-FI',
  'fr': 'fr-FR',
  'he': 'he-IL',
  'hi': 'hi-IN',
  'hr': 'hr-HR',
  'hu': 'hu-HU',
  'id': 'id-ID',
  'it': 'it-IT',
  'ja': 'ja-JP',
  'ko': 'ko-KR',
  'lzh': 'zh-CN',
  'nl': 'nl-NL',
  'no': 'no-NO',
  'pl': 'pl-PL',
  'pt': 'pt-PT',
  'ro': 'ro-RO',
  'ru': 'ru-RU',
  'sk': 'sk-SK',
  'sv': 'sv-SE',
  'th': 'th-TH',
  'tr': 'tr-TR',
  'vi': 'vi-VN',
  'yue': 'zh-HK',
  'zh-Hans': 'zh-CN',
  'zh-Hant': 'zh-TW',
};

class TextToSpeechComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: props.text,
      language: props.language,
      isPlaying: false,
    };

    this.synth = window.speechSynthesis;
    this.synth.cancel();

    // this.handleTextChange = this.handleTextChange.bind(this);
    this.handlePlayButtonClick = this.handlePlayButtonClick.bind(this);
    this.handleStopButtonClick = this.handleStopButtonClick.bind(this);
    this.handleAudioEnd = this.handleAudioEnd.bind(this);
  }

  // This method is called when the component receives new props
  componentDidUpdate(prevProps) {
    // Check if the specific prop you want to monitor has changed
    if (prevProps.text !== this.props.text) {
      // Handle the prop change here
      this.setState({ text: this.props.text });
      this.setState({ language: this.props.language });
    }
  }

  // handleTextChange(event) {
  //   this.setState({ text: event.target.value });
  // }

  handlePlayButtonClick() {
    if (this.props.text === '') {
      return;
    }

    // console.log("text to play", this.state.language, langCode2TTSLang[this.state.language ?? 'en']);
    const utterance = new SpeechSynthesisUtterance(this.state.text);
    utterance.lang = langCode2TTSLang[this.state.language ?? 'en'] ?? this.state.language;
    utterance.rate = Utils.getLocalStorageProperty(Utils.SETTINGS_TTS_VOICE_SPEED) ?? Utils.DEFAULT_TTS_VOICE_SPEED;
    this.synth.cancel();
    this.synth.speak(utterance);

    this.setState({ isPlaying: true });
    utterance.onend = this.handleAudioEnd;
  }

  handleStopButtonClick() {
    this.synth.cancel();
    this.setState({ isPlaying: false });
  }

  handleAudioEnd() {
    this.setState({ isPlaying: false });
  }

  render() {
    return (
      <div>
        {this.state.isPlaying ? (
           <button id="stopButtonId" className="ui mini basic button" 
            onClick={this.handleStopButtonClick} style={{ position: 'absolute', bottom: '8px', left: '10px', boxShadow: 'none', padding: '6px'}}>
            <img src={stopIcon} style={{ width: '20px' }} alt="stopButton" /></button>
        ) : (
          <button id="speakerButtonId" className="ui mini basic button" 
            onClick={this.handlePlayButtonClick} style={{ position: 'absolute', bottom: '8px', left: '10px', boxShadow: 'none', padding: '6px'}}>
            <img src={speakerIcon} style={{ width: '20px' }} alt="speakerButton" /></button>
        )}
      </div>
    );
  }
}

export default TextToSpeechComponent;