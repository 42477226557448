
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {HomePage} from "./HomePage";
import PrivacyPolicyPage from './ui_views/PrivacyPolicy';
import TermsOfServicePage from './ui_views/TermsOfService';
import SettingsPage from './ui_views/Settings';
import HelpCenterPage from './ui_views/HelpCenter';
import TranslationHistoryPage from './ui_views/TranslationHistoryPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route path="/privacyPolicy" element={<PrivacyPolicyPage/>} />
        <Route path="/termsOfService" element={<TermsOfServicePage/>} />
        <Route path="/settings" element={<SettingsPage/>} />
        <Route path="/helpCenter" element={<HelpCenterPage/>} />
        <Route path="/history" element={<TranslationHistoryPage/>} />      
      </Routes>
    </Router>
  );
};

export default App;


// import logo from "./images/translateGPT_128.png";
// import {
//   withAuthenticator,
//   Button,
//   Heading,
//   Image,
//   View,
//   Card,
//   Auth, 
//   Text,
//   Authenticator, 
//   useAuthenticator,
//   useTheme
// } from "@aws-amplify/ui-react";
// import axios from "axios";

// function App({ signOut, user }) {
//   function translate() {
//     const TRANSLATE_URL = "https://bjostwnl2hsnuhllkomqivfrta0wabjp.lambda-url.us-west-2.on.aws/"
//     //const TRANSLATE_URL_TEST = "https://59lb2r4nzh.execute-api.us-west-2.amazonaws.com/dev";
//     const requestData = {
//       text: "Hello world!",
//       destinationLanguage: "zh",
//     };
//     //axios.defaults.withCredentials = true;
//     axios.post(TRANSLATE_URL, requestData, {
//         headers: {
//           // Accept: "application/json",
//           // "Content-Type": "application/json",
//           //"Access-Control-Allow-Headers": "*",
//         },
//       })
//       .then(({data}) => {
//         console.log(data);
//         alert('translated text is ' + data);
//     });
//   }


//   // if (user) {
//     return (
//       <View className="App">
//         <HomePage></HomePage>
//         <Card>
//           <Image src={logo} className="App-logo" alt="logo" />
//           <Heading level={1}>We now have Auth!</Heading>
//         </Card>
//         <Button onClick={signOut}>Sign Out</Button>
//         <Button onClick={translate}>Translate</Button>
//       </View>
//     );
//   // } else {
//   //   return (<div>
//   //     <h1>Welcome to Our App!</h1>
//   //     <p>Please sign in to continue.</p>
//   //     </div>
//   //   )
//   // }
// }

// export default App;

// export default withAuthenticator(App);

// const components = {
//   Header() {
//     const { tokens } = useTheme();

//     return (
//       <View textAlign="center" padding={tokens.space.large}>
//         <Text color={tokens.colors.purple}>
//           TranslateGPT
//         </Text>
//         {/* <Image
//           alt="Amplify logo"
//           src="https://docs.amplify.aws/assets/logo-dark.svg"
//         /> */}
//       </View>
//     );
//   },

//   Footer() {
//     const { tokens } = useTheme();

//     return (
//       <View textAlign="center" padding={tokens.space.large}>
//         <Text color={tokens.colors.neutral[80]}>
//           &copy; All Rights Reserved
//         </Text>
//         <select id="languageSelection">
//           <option value="en">English</option>
//           <option value="es">Spanish</option>
//           <option value="fr">French</option>
//           <option value="zh">Chinese</option>
//         </select>
//       </View>
//     );
//   },

//   SignIn: {
//     Header() {
//       const { tokens } = useTheme();

//       return (
//         <Heading
//           padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
//           level={3}
//         >
//           Sign in to your account
//         </Heading>
//       );
//     },
//     Footer() {
//       const { toResetPassword } = useAuthenticator();

//       return (
//         <View textAlign="center">
//           <Button
//             fontWeight="normal"
//             onClick={toResetPassword}
//             size="small"
//             variation="link"
//           >
//             Reset Password
//           </Button>
//         </View>
//       );
//     },
//   },

//   SignUp: {
//     Header() {
//       const { tokens } = useTheme();

//       return (
//         <Heading
//           padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
//           level={3}
//         >
//           Create a new account
//         </Heading>
//       );
//     },
//     Footer() {
//       const { toSignIn } = useAuthenticator();

//       return (
//         <View textAlign="center">
//           <Button
//             fontWeight="normal"
//             onClick={toSignIn}
//             size="small"
//             variation="link"
//           >
//             Back to Sign In
//           </Button>
//         </View>
//       );
//     },
//   },
//   ConfirmSignUp: {
//     Header() {
//       const { tokens } = useTheme();
//       return (
//         <Heading
//           padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
//           level={3}
//         >
//           Enter Information:
//         </Heading>
//       );
//     },
//     Footer() {
//       return <Text>Footer Information</Text>;
//     },
//   },
//   SetupTOTP: {
//     Header() {
//       const { tokens } = useTheme();
//       return (
//         <Heading
//           padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
//           level={3}
//         >
//           Enter Information:
//         </Heading>
//       );
//     },
//     Footer() {
//       return <Text>Footer Information</Text>;
//     },
//   },
//   ConfirmSignIn: {
//     Header() {
//       const { tokens } = useTheme();
//       return (
//         <Heading
//           padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
//           level={3}
//         >
//           Enter Information:
//         </Heading>
//       );
//     },
//     Footer() {
//       return <Text>Footer Information</Text>;
//     },
//   },
//   ResetPassword: {
//     Header() {
//       const { tokens } = useTheme();
//       return (
//         <Heading
//           padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
//           level={3}
//         >
//           Enter Information:
//         </Heading>
//       );
//     },
//     Footer() {
//       return <Text>Footer Information</Text>;
//     },
//   },
//   ConfirmResetPassword: {
//     Header() {
//       const { tokens } = useTheme();
//       return (
//         <Heading
//           padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
//           level={3}
//         >
//           Enter Information:
//         </Heading>
//       );
//     },
//     Footer() {
//       return <Text>Footer Information</Text>;
//     },
//   },
// };

// const formFields = {
//   signIn: {
//     username: {
//       placeholder: 'Enter your email',
//     },
//   },
//   signUp: {
//     password: {
//       label: 'Password:',
//       placeholder: 'Enter your Password:',
//       isRequired: false,
//       order: 2,
//     },
//     confirm_password: {
//       label: 'Confirm Password:',
//       order: 1,
//     },
//   },
//   forceNewPassword: {
//     password: {
//       placeholder: 'Enter your Password:',
//     },
//   },
//   resetPassword: {
//     username: {
//       placeholder: 'Enter your email:',
//     },
//   },
//   confirmResetPassword: {
//     confirmation_code: {
//       placeholder: 'Enter your Confirmation Code:',
//       label: 'New Label',
//       isRequired: false,
//     },
//     confirm_password: {
//       placeholder: 'Enter your Password Please:',
//     },
//   },
//   setupTOTP: {
//     QR: {
//       totpIssuer: 'test issuer',
//       totpUsername: 'amplify_qr_test_user',
//     },
//     confirmation_code: {
//       label: 'New Label',
//       placeholder: 'Enter your Confirmation Code:',
//       isRequired: false,
//     },
//   },
//   confirmSignIn: {
//     confirmation_code: {
//       label: 'New Label',
//       placeholder: 'Enter your Confirmation Code:',
//       isRequired: false,
//     },
//   },
// };

// export default function App() {
//   return (
//     <Authenticator formFields={formFields} components={components}>
//       {({ signOut }) => 
//         <button onClick={signOut}>Sign out</button>

//       }
//     </Authenticator>
//   );
// }



// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <h1>Hello from V2</h1>
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
