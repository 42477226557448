import React from 'react';
import appLogo from "../images/translateGPT_128.png";

const Footer = () => {
  return (
    <div id="footerSectionId" className="ui vertical footer segment">
    <div className="ui container">
      <div className="ui stackable equal height stackable grid">
        <div className="four wide column">
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <img src={appLogo} alt="logo" style={{ width: '25px', height: '25px', marginRight: '8px' }} />
            <div style={{ fontSize: '17px', display: 'inline', fontWeight: '500' }}>XTranslateGPT</div>
          </div>
        </div>
        <div className="four wide column">
          <h4 className="ui header">Resources</h4>
          <div className="ui link list">
            <a href="/helpCenter" className="item">Help Center</a>
            <a href="https://aiworksuite.com/contact-us" className="item">Contact Us</a>
            <a href="/privacyPolicy" className="item">Privacy Policy</a>
            <a href="/termsOfService" className="item">Terms of Service</a>
          </div>
        </div>
        <div className="six wide column">
          <h4 className="ui header">Download</h4>
          <div className="ui link list">
            <a href="https://workspace.google.com/marketplace/app/workspace_translator_with_gpt_translate/257171559362" className="item">XTranslateGPT for Google Workspace</a>
            <a href="https://chrome.google.com/webstore/detail/translategpt-translate-al/dnffnnhonjgemhiegeenagikblhnfhaa" className="item">XTranslateGPT Chrome extension</a>
          </div>
        </div>
        <div className="four wide column">
        </div>
        {/* <div className="seven wide column">
          <h4 className="ui header">Footer Header</h4>
          <p>Extra space for a call to action inside the footer that could help re-engage users.</p>
        </div> */}
      </div>
    </div>
    <div className="centered" style={{marginTop: '40px'}}>
      <div>Copyright © 2023 ZQ Labs, Inc. - All Rights Reserved.</div>
    </div>
  </div>
  )

};

export default Footer;