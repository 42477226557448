import React from 'react';
import appLogo from "../images/translateGPT_128.png";
import './CommonStyles.css';

const Header = () => {
  return (
    <div className="ui large secondary menu" style={{paddingTop: '20px', paddingBottom: '20px'}}>
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}>
        <img src={appLogo} alt="logo" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
        <div className="common-font" style={{ fontSize: '19px', display: 'inline', fontWeight: '500' }}>XTranslateGPT</div>
      </div>

      <a className="item common-font" href="/">Home</a>
      <a className="item common-font" href="/">Products</a>
      <a className="item common-font" href="/">Premium Plan</a>
    </div>
  );

};

export default Header;