export const LANGUAGE_MAP =
  [ { text: 'English', val: 'en' },
    { text: 'Spanish', val: 'es' },
    { text: 'Chinese (Simplified)', val: 'zh-CN' },
    { text: 'Afrikaans', val: 'af' },
    { text: 'Albanian', val: 'sq' },
    { text: 'Amharic', val: 'am' },
    { text: 'Arabic', val: 'ar' },
    { text: 'Armenian', val: 'hy' },
    { text: 'Azerbaijani', val: 'az' },
    { text: 'Basque', val: 'eu' },
    { text: 'Belarusian', val: 'be' },
    { text: 'Bengali', val: 'bn' },
    { text: 'Bosnian', val: 'bs' },
    { text: 'Bulgarian', val: 'bg' },
    { text: 'Catalan', val: 'ca' },
    { text: 'Cebuano', val: 'ceb' },
    // { text: 'Chinese (Simplified)', val: 'zh-CN' },
    { text: 'Chinese (Traditional)', val: 'zh-TW' },
    { text: 'Corsican', val: 'co' },
    { text: 'Croatian', val: 'hr' },
    { text: 'Czech', val: 'cs' },
    { text: 'Danish', val: 'da' },
    { text: 'Dutch', val: 'nl' },
    // { text: 'English', val: 'en' },
    { text: 'Esperanto', val: 'eo' },
    { text: 'Estonian', val: 'et' },
    { text: 'Finnish', val: 'fi' },
    { text: 'French', val: 'fr' },
    { text: 'Frisian', val: 'fy' },
    { text: 'Galician', val: 'gl' },
    { text: 'Georgian', val: 'ka' },
    { text: 'German', val: 'de' },
    { text: 'Greek', val: 'el' },
    { text: 'Gujarati', val: 'gu' },
    { text: 'Haitian Creole', val: 'ht' },
    { text: 'Hausa', val: 'ha' },
    { text: 'Hawaiian', val: 'haw' },
    { text: 'Hebrew', val: 'he' },
    { text: 'Hindi', val: 'hi' },
    { text: 'Hmong', val: 'hmn' },
    { text: 'Hungarian', val: 'hu' },
    { text: 'Icelandic', val: 'is' },
    { text: 'Igbo', val: 'ig' },
    { text: 'Indonesian', val: 'id' },
    { text: 'Irish', val: 'ga' },
    { text: 'Italian', val: 'it' },
    { text: 'Japanese', val: 'ja' },
    { text: 'Javanese', val: 'jv' },
    { text: 'Kannada', val: 'kn' },
    { text: 'Kazakh', val: 'kk' },
    { text: 'Khmer', val: 'km' },
    { text: 'Korean', val: 'ko' },
    { text: 'Kurdish', val: 'ku' },
    { text: 'Kyrgyz', val: 'ky' },
    { text: 'Lao', val: 'lo' },
    { text: 'Latin', val: 'la' },
    { text: 'Latvian', val: 'lv' },
    { text: 'Lithuanian', val: 'lt' },
    { text: 'Luxembourgish', val: 'lb' },
    { text: 'Macedonian', val: 'mk' },
    { text: 'Malagasy', val: 'mg' },
    { text: 'Malay', val: 'ms' },
    { text: 'Malayalam', val: 'ml' },
    { text: 'Maltese', val: 'mt' },
    { text: 'Maori', val: 'mi' },
    { text: 'Marathi', val: 'mr' },
    { text: 'Mongolian', val: 'mn' },
    { text: 'Myanmar (Burmese)', val: 'my' },
    { text: 'Nepali', val: 'ne' },
    { text: 'Norwegian', val: 'no' },
    { text: 'Nyanja (Chichewa)', val: 'ny' },
    { text: 'Pashto', val: 'ps' },
    { text: 'Persian', val: 'fa' },
    { text: 'Polish', val: 'pl' },
    { text: 'Portuguese (Portugal, Brazil)', val: 'pt' },
    { text: 'Punjabi', val: 'pa' },
    { text: 'Romanian', val: 'ro' },
    { text: 'Russian', val: 'ru' },
    { text: 'Samoan', val: 'sm' },
    { text: 'Scots Gaelic', val: 'gd' },
    { text: 'Serbian', val: 'sr' },
    { text: 'Sesotho', val: 'st' },
    { text: 'Shona', val: 'sn' },
    { text: 'Sindhi', val: 'sd' },
    { text: 'Sinhala (Sinhalese)', val: 'si' },
    { text: 'Slovak', val: 'sk' },
    { text: 'Slovenian', val: 'sl' },
    { text: 'Somali', val: 'so' },
    // { text: 'Spanish', val: 'es' },
    { text: 'Sundanese', val: 'su' },
    { text: 'Swahili', val: 'sw' },
    { text: 'Swedish', val: 'sv' },
    { text: 'Tagalog (Filipino)', val: 'tl' },
    { text: 'Tajik', val: 'tg' },
    { text: 'Tamil', val: 'ta' },
    { text: 'Telugu', val: 'te' },
    { text: 'Thai', val: 'th' },
    { text: 'Turkish', val: 'tr' },
    { text: 'Ukrainian', val: 'uk' },
    { text: 'Urdu', val: 'ur' },
    { text: 'Uzbek', val: 'uz' },
    { text: 'Vietnamese', val: 'vi' },
    { text: 'Welsh', val: 'cy' },
    { text: 'Xhosa', val: 'xh' },
    { text: 'Yiddish', val: 'yi' },
    { text: 'Yoruba', val: 'yo' },
    { text: 'Zulu', val: 'zu' }
  ];

  export const AUTO_DETECT_LANGUAGE_VALUE = "autoDetect";
  export const SOURCE_LANGUAGE_MAP = [{text: 'Auto detect language', val: AUTO_DETECT_LANGUAGE_VALUE}, ...LANGUAGE_MAP];
  