import React from 'react';
import { createRoot } from 'react-dom/client'
import { Authenticator, View, Button } from '@aws-amplify/ui-react';
import Modal from 'react-modal';
import '@aws-amplify/ui-react/styles.css';
import { Hub } from 'aws-amplify';
import * as AuthUtils from './AuthUtils.js';
import * as Utils from './Utils.js';

const MAX_zIndex = "2147483647"

export function showUserSignInOrOutPopup(user, $elementWhichAuthAppendTo, isSignUp, callbackAfterDone = () => {}) {
  const signInRootDiv = document.createElement('div');
  signInRootDiv.id = 'signInRoot';
  const signInRoot = createRoot(signInRootDiv);
  $elementWhichAuthAppendTo.appendChild(signInRootDiv);

  const closeModal = () => {
    //console.log("close Modal")
    signInRoot.unmount();
    if ($elementWhichAuthAppendTo.contains(signInRootDiv)) {
      $elementWhichAuthAppendTo.removeChild(signInRootDiv);
    }
  };

  Hub.listen('auth', (data) => {
    // eslint-disable-next-line default-case
    switch (data.payload.event) {
      case 'signIn':
          console.log('user signed in');
          closeModal();
          //$elementWhichAuthAppendTo.style.display = "none";
          callbackAfterDone();
          break;
      case 'signUp':
        console.log('user signed up');
        break;
      case 'signOut':
          console.log('user signed out');
          closeModal();
          callbackAfterDone();
          break;
      case 'signIn_failure':
          console.log('user sign in failed');
          break;
      case 'configured':
          console.log('the Auth module is configured');
    }
  });

  //const amplify_css = chrome.runtime.getURL("utils/amplify_ui.css");
  if (user) {
    signInRoot.render(<Modal
      isOpen={true}
      contentLabel=""
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: MAX_zIndex - 1,
        },
        content: {
          width: '80%',
          height: "70%",
          maxWidth: '240px',
          maxHeight: '140px',
          margin: 'auto',
          padding: '0px',
          zIndex: MAX_zIndex
        },
      }}
    >
      {/* <link rel="stylesheet" type="text/css" /> */}
      <Authenticator>
       {({signOut}) => (
            <main>
              <h4 style={{ padding: '20px 0px', textAlign: 'center' }}>Do you want to sign out?</h4>
              <div style= {{display: 'flex', justifyContent: 'center'}} >
                <button className="ui button" onClick={() => { signOut(); }}>Sign out</button>
                <button className="ui primary button" onClick={closeModal}>Cancel</button>
              </div>
            </main>
        )}
      </Authenticator>, 
    </Modal>);
  } else {
    //console.log("show user sign up and sign in popup");
    const components = {    
      SignIn: {
        Footer() {    
          return (
            <View textAlign="center">
              <Button fontWeight="normal" marginBottom="10px" onClick={closeModal} size="small" variation="link">Close</Button>
              <br></br>
            </View>
          );
        },
      },
      SignUp: {
        Footer() {    
          return (
            <View textAlign="center">
              <Button fontWeight="normal" marginBottom="10px" onClick={closeModal} size="small" variation="link">Close</Button>
            </View>
          );
        },
      },
    }

    signInRoot.render(<Modal
        isOpen={true}
        contentLabel=""
        ariaHideApp={false}
        style={{
          overlay: {
            zIndex: MAX_zIndex - 1, 
          },
          content: {
            width: '80%',
            height: "70%",
            maxWidth: '800px',
            maxHeight: '500px',
            margin: 'auto',
            padding: '0px',
            border: 'none',
            background: 'none', 
            zIndex: MAX_zIndex
          },
        }}
      >
      <Authenticator components={components} initialState={isSignUp ? "signUp" : "signIn"} socialProviders={['google', 'apple']}>
      </Authenticator>
    </Modal>);
  }
}

export const onOpenMonthlySubscriptionLink = (userEmail) => {
  window.open("https://buy.stripe.com/8wMbKQetTfVH4iA6p1?prefilled_email=" + encodeURIComponent(userEmail));
}

export const onOpenYearlySubscriptionLink = (userEmail) => {
  window.open('https://buy.stripe.com/aEU3ekfxX5h316o28M?prefilled_email=' + encodeURIComponent(userEmail));
}

export const updateSubscriptionState = async (target) => {
  const session = await AuthUtils.currentSession();
  if (session?.getAccessToken()?.getJwtToken()) {
    await Utils.fetchSubscriptionStateFromBackend(session?.getAccessToken()?.getJwtToken());
  } else {
    showUserSignInOrOutPopup(null, target, false);
  }
}

export function showPurchasePremiumPopup($upgradeToPurchaseButtonElement, userEmail) {
  const purchasePremiumDiv = document.createElement('div');
  purchasePremiumDiv.id = 'purchasePremiumRoot';
  const purchasePremiumRoot = createRoot(purchasePremiumDiv);
  $upgradeToPurchaseButtonElement.appendChild(purchasePremiumDiv);

  const closeModal = () => {
    //console.log("close Modal")
    purchasePremiumRoot.unmount();
    $upgradeToPurchaseButtonElement.removeChild(purchasePremiumDiv);
  }

  purchasePremiumRoot.render(<Modal
    isOpen={true}
    contentLabel=""
    ariaHideApp={false}
    style={{
      overlay: {
        zIndex: MAX_zIndex - 1,
        inset: '0px'
      },
      content: {
        width: '105%',
        height: "100%",
        maxWidth: '600px',
        maxHeight: '700px',
        margin: 'auto',
        padding: '10px 30px',
        inset: '0px',
        zIndex: MAX_zIndex
      },
    }}
  >
     <div>
      <link async rel="stylesheet" href="https://cdn.jsdelivr.net/npm/semantic-ui@2/dist/semantic.min.css"/>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/water.css@2/out/water.css"></link>
      <h4 style={{ color: '#4285F4' }}>
        <b>Upgrade to Premium Service with great benefits and features:</b>
      </h4>
      <div className="ui divider"></div>
      <label style={{ padding: '5px' }}>
        1. <b>200K</b> characters/Day for you to use and translate.<br />
        2. <b>Accessible to advanced Premium features</b> (summarize, dictionary, pronunciations, synonyms, rewrite, detect langauges, explain, transliterate, history and more!!) to help you. <br />
        3. <b>8x Longer</b> translation input supported up to 20000 characters and 4000 words each time.<br />
        4. <b>Support Documents translation</b> with 10 files/month.<br />
        5. <b>Support images translation</b> without limit. <br />
        6. <b>Multiple apps supported</b>, Google Workspace, WebApp, Chrome Extension. iOS and Android are also coming! <br />
        7. <b>Faster response</b> with dedicated GPT server. <br />
        8. <b>No ads</b>. <br />
        9. <b>Priority support</b> for your questions and issues. <br />
        Current offers available:
      </label>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button id="monthlyPremiumButtonId"
          style={{ margin: '15px 0px 10px', width: '90%', maxWidth: '400px', minHeight: '40px', 
          backgroundColor: '#06ADEF', color: 'white' }}
          onClick={() => onOpenMonthlySubscriptionLink(userEmail)}
        >
          <b>Monthly Premium for $5.99 (15% off)</b>
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          id="yearlyPremiumButtonId"
          style={{ margin: '0px 0px 10px', width: '90%', maxWidth: '400px', minHeight: '40px', 
          backgroundColor: 'rgb(0, 119, 255)', color: 'white' }}
          onClick={() => onOpenYearlySubscriptionLink(userEmail)}
        >
          <b>Yearly Premium for $44.99 (50% off)</b>
        </button>
      </div>
      <label style={{ paddingRight: '5px' }}>
        <br />
        <b>Note: Please make sure to use and put your Account Email <span style={{color: 'rgb(0, 119, 255)'}}>{userEmail}</span></b> when making the payment to get the Premium license for this email account.
        The Premium serivce will be linked to the email address you enter and use during the payment. <br /><br />
        After you finish the purchase, please click the "Already purchased and refresh" button below to reload the UI and get the subscription.
      </label>
      <div className="ui divider"></div>
      <label style={{ paddingRight: '5px', color: 'gray' }}>
        Have more questions, or want to purchase a license for a group of people or the whole team/company?
        Please contact us at <a href="https://www.aiworksuite.com/contact-us">aiworksuite.com/contact-us</a>.
        We are happy to answer your questions or discuss related offers.
      </label>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button id="tryPremiumLaterButtonId" style={{ margin: '30px 0px 10px', width: '40%' }} 
          className="ui basic button" onClick={closeModal}>
          Try Premium later
        </button>
        <button style={{ margin: '30px 15px 10px', width: '50%' }} className="ui green basic button"
          onClick={(event) => {updateSubscriptionState(event.target); closeModal(); } }>
          Already Purchased and refresh
        </button>
      </div>
    </div>
  </Modal>);

}